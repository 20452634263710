import classes from './Record.module.scss'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Fragment, useEffect, useRef } from 'react';
import IconOpenWindow from '../Icons/IconOpenWindow';
import IconAngleLeft from '../Icons/IconAngleLeft';

let scene, camera, renderer, modelLoader, animation

function RecordModel(props) {
  const wrapperRef = useRef()

  useEffect(() => {
    animation = null
    initGraphics()
    animate()
    window.addEventListener("resize", resize)
    return () => {
      window.removeEventListener("resize", resize)
      cancelAnimationFrame( animation )
    }
  },[])

  function initGraphics() {
    scene = new THREE.Scene()
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.position.z = 5
    
    renderer = new THREE.WebGLRenderer({alpha: true, antialias: true})
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.outputEncoding = THREE.sRGBEncoding
    wrapperRef.current.appendChild(renderer.domElement)

    const controls = new OrbitControls( camera, renderer.domElement );
    controls.enableZoom = false
    controls.enablePan = false

    // LIGHTS
    const amblight = new THREE.AmbientLight(0xFFFFFF, 0.6)
    scene.add(amblight)

    // MODELS
    let modelUrl = 'https://d1ebxi7lthqj9n.cloudfront.net/models/record.glb'
    modelLoader = new GLTFLoader();
    modelLoader.load(modelUrl, gltf => {
      const record = gltf.scene;
      record.scale.set(10,10,10)
      scene.add(record)
    })
  }

  function animate() {
    animation = requestAnimationFrame(animate)
    renderer.render(scene, camera)
  }

  function resize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  return(
    <Fragment>
      <div ref={wrapperRef} className={classes.wrapper}></div>
      <div className={classes.topContent}>
        <div className={classes.close} onClick={props.closeItem}><IconAngleLeft /> BACK</div>
        <h3>{props.currentItem.title}</h3>
      </div>

      <a
        href='https://linkinpedia.com/index.php?title=Mr._Hahn_Fucks_Up_Meteora_Style'
        target='_blank'
        rel='noreferrer'
        className={classes.cta}
      >
        LEARN MORE
        <IconOpenWindow />
      </a>
    </Fragment>
  )
}

export default RecordModel