import { Fragment } from "react"
import classes from './Gallery.module.scss'
import IconOpenWindow from '../../components/Icons/IconOpenWindow'

function Gallery4(props) {
  const images = [
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_00_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_01_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_02_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_03_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_04_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_05_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_06_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_07_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_08_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_09_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_10_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_11_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_12_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_13_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_14_v2.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-4/ContactSheetReject_15_v2.jpg'
  ]

  return(
    <Fragment>
      <img src={images[props.index]} data-active={true} data-index={props.index} />
      <a href={images[props.index]} target='_blank' rel='noreferrer' className={classes.zoomLink}>
        Open full-size image
        <IconOpenWindow />
      </a>
    </Fragment>
  )
}

export default Gallery4