import classes from './Vinyl.module.scss'

import { Fragment } from "react"

function VinylBoxSet() {
  return(
    <Fragment>
      <h3>LIMITED EDITION SUPER DELUXE BOX SET INCLUDES:</h3>

      <div className='flex-between'>
        <div className={classes.boxSetWrap}>
          <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/LP_M20_DELUXE-2_v2.png' />
          <div className={classes.boxSetInfo}>
            <h3>5 LPS</h3>
            <ul>
              <li>METEORA (1 LP)</li>
              <li>LIVE IN TEXAS (2 LP) *DEBUT VINYL RELEASE</li>
              <li>LIVE IN NOTTINGHAM 2003 (2 LP) *PREVIOUSLY UNRELEASED</li>
            </ul>

            <h3>3 DVDS</h3>(INCLUDING 4 PREVIOUSLY UNRELEASED CONCERTS)
            <ul>
              <li>VETERANS STADIUM 2003, LIVE IN SEOUL 2003</li>
              <li>LIVE IN MANILA 2004, LIVE IN DENVER - PROJEKT REVOLUTION 2004</li>
              <li>THE MAKING OF METEORA/THE ART OF METEORA & THE NEW WORK IN PROGRESS LIFESTYLE DOCUMENTARY</li>
            </ul>

            <h3>4 CDS</h3>
            <ul>
              <li>METEORA</li>
              <li>LPU RARITIES 2.0</li>
              <li>LIVE RARITIES 2003-2004</li>
              <li>LOST DEMOS (PREVIOUSLY UNRELEASED)</li>
            </ul>

            <p>
              INCLUDES DELUXE PACKAGING, 40-PAGE BOOK, 36” X 24” POSTER OF THE METEORA WALL, LITHO CREATED BY DELTA (THE STREET ARTIST FEATURED ON THE ALBUM COVER), RETRO STICKER SHEET, LINKIN PARK LOGO STENCIL & HI-RES DIGITAL DOWNLOAD CARD
            </p>
          </div>

          {/* <div className={classes.boxSetScrollTip}>SCROLL</div> */}
        </div>
        
        <a href='https://meteora20.lnk.to/SuperDeluxe' target='_blank' rel='noreferrer' className='btn btn--outline'>ORDER NOW</a>
      </div>
    </Fragment>
  )
}

export default VinylBoxSet