import { useState } from 'react'
import IconLp from '../Icons/IconLp'
import classes from './Controls.module.scss'

function StencilControl(props) {
  const [flyOutActive, setFlyoutActive] = useState(true)

  function handleClick() {
    props.startStencilMode()

    if (props.mode === 'stencil') {
      setFlyoutActive(flyOutActive ? false : true)
    } else {
      setFlyoutActive(true)
    }  
  }

  function closeFlyout() {
    setFlyoutActive(false)
  }

  return (
    <div className={classes.control}>
      <button onClick={handleClick} data-active={props.mode === 'stencil'}>
        <IconLp />
        Stencil
      </button>
      {props.mode === 'stencil' && flyOutActive && (
        <div className={classes.flyOut}>
          <div className={classes.flyOutTitle}>Stencils</div>
          <div className={classes.option}>
            <div className={classes.radioGroup}>
              {['1','2','3'].map(stencil => (
                <div key={`stencil_${stencil}`} className={classes.radioOption}>
                  <input
                    id={`stencil-select-${stencil}`}
                    type='radio'
                    name='stencil'
                    defaultValue={stencil}
                    onChange={props.handleStencilChange}
                    checked={props.currentStencil === stencil}
                  />
                  <label htmlFor={`stencil-select-${stencil}`}>
                    <img src={`/images/paint/stencils/stencil-${stencil}.png`} />
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div>
            <button onClick={closeFlyout} className='btn btn--block'>Close</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default StencilControl