import { Fragment } from "react"
import classes from './Gallery.module.scss'
import IconOpenWindow from '../../components/Icons/IconOpenWindow'

function Gallery1(props) {
  const images = [
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/folder.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/girl-and-paper-idea.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/poster-comp.jpg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_1.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_2.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_3.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_4.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_5.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_6.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_7.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_8.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_11.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_12.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_b.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_c.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_c1.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_c5.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_c6.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_c7.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_c8.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_c9.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ch1.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ch2.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ch3.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ch4.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ch5.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ch6.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ch7.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ch8.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ch9.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_d.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_e.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_f.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_g.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_h.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ms.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ms1.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ms2.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ms3.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ms4.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ms5.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ms6.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_ms7.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa1.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa2.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa3.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa4.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa5.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa6.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa7.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa8.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa9.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa10.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa11.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa112.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa13.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa14.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa15.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa16.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa17.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msa18.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msc2.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msc3.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msc4.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msc5.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msc6.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msc7.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msc8.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msc9.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch1.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch2.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch3.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch4.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch5.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch6.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch7.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch8.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-1/sketches_msch9.jpeg',
  ]



  return(
    <Fragment>
      <img src={images[props.index]} data-active={true} data-index={props.index} />
      <a href={images[props.index]} target='_blank' rel='noreferrer' className={classes.zoomLink}>
        Open full-size image
        <IconOpenWindow />
      </a>
    </Fragment>
     
  )
}

export default Gallery1