import { useState } from 'react';
import Modal from '../Ui/Modal';
import Voting from '../Voting/Index';
import classes from './VoteWrap.module.css';

function VoteWrap(props) {
  const [votingUi, setVotingUi] = useState(false);

  const startVoting = () => {
    props.clearBracket();
    setVotingUi(true);
  }

  const stopVoting = () => {
    setVotingUi(false);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.whiteboard}>
        <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/meteora-song-bracket_modal_winner-02.jpg' />

        {props.winners.map((winner, i) => (
          <div key={`winner_${i}`} className={classes.winner} data-index={i}>{winner.title}</div>
        ))}


        <div className={classes.close} onClick={props.closeVoting}></div>
        {/* {window.location.href.includes('meteora.linkinpark.com') && <div className={classes.startButton} onClick={startVoting}></div>} */}
      </div>

      <Modal
        modalActive={votingUi}
        allowClose={true}
        closeModal={stopVoting}
      >
        <Voting addWinner={props.addWinner} closeVoting={props.closeVoting} updateBracket={props.updateBracket}/>
      </Modal>
    </div>
  )
}

export default VoteWrap;