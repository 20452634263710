import IconAngleLeft from '../Icons/IconAngleLeft'
import classes from './Header.module.scss'

function Header(props) {
  return(
    <div className={classes.wrapper} data-menuactive={props.menuActive}>
      {props.interaction && (
        <div className={`btn--link ${classes.backBtn}`} onClick={props.closeInteraction}><IconAngleLeft /> BACK TO WAREHOUSE</div>
      )}
      <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/header-logo.png' className={classes.logo} />
      <div onClick={props.toggleMenu} className={classes.menuToggle}>
        <div className={classes.iconCenter}></div>
        <div className={classes.iconAnimate}></div>
      </div>
    </div>
  )
}

export default Header