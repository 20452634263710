import { useState } from 'react'
import IconSprayCan from '../Icons/IconSprayCan'
import IconMarker from '../Icons/IconMarker'
import classes from './Controls.module.scss'

function BrushControl(props) {
  const [flyOutActive, setFlyoutActive] = useState(true)

  function handleClick() {
    props.startDrawMode()

    if (props.mode === props.brushType) {
      setFlyoutActive(flyOutActive ? false : true)
    } else {
      setFlyoutActive(true)
    }  
  }

  function closeFlyout() {
    setFlyoutActive(false)
  }

  return (
    <div className={classes.control}>
      <button onClick={handleClick} data-active={props.mode === props.brushType}>
        {props.brushType === 'draw' ? <IconSprayCan /> : <IconMarker />}
        {props.brushType === 'draw' ? 'Spray Can' : 'Marker'}
      </button>
      {props.mode === props.brushType && flyOutActive && (
        <div className={classes.flyOut}>
          <div className={classes.flyOutTitle}>{props.brushType === 'draw' ? 'Spray Can' : 'Marker'}</div>
          <div className={classes.option}>
            <label>SIZE</label>
            <input
              type='range'
              name="brush_size"
              defaultValue={props.currentSize}
              min='10'
              max='50'
              onChange={props.handleSizeChange}
            />
          </div>
          {props.brushType === 'draw' && (
            <div className={classes.option}>
              <label>DENSITY</label>
              <input
                type='range'
                name="brush_density"
                defaultValue={props.currentDensity}
                min='20'
                max='100'
                onChange={props.handleDensityChange}
              />
            </div>
          )}
          <div className={classes.option}>
            <label htmlFor="head">COLOR</label><br/>
            <input
              type="color"
              id="head"
              name="brush_color"
              defaultValue={props.currentColor}
              onChange={props.handleColorChange}
            />
          </div>
          <div>
            <button onClick={closeFlyout} className='btn btn--block'>Close</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default BrushControl