import { useEffect, useState } from 'react'
import classes from './VideoPlayer.module.scss'

function VideoPlayer(props) {
  const [currentVideo, setCurrentVideo] = useState();
  const [playlistVideos, setPlaylistVideos] = useState([]);
  
  useEffect(() => {
    getVideos()
  }, []);

  const getVideos = async () => {
    let videos = [];

    const response1 = await fetch('https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyACKl1vQQHr52eb5j7H4npokVwzG1R0lkM&playlistId=PLlqZM4covn1Hqp7LaICvTomOliPy4wZHp&part=snippet&maxResults=50')

    if (!response1.ok) {
      console.log(response1)
    }
    
    const responseData1 = await response1.json();
    videos = responseData1.items;

    const response2 = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyACKl1vQQHr52eb5j7H4npokVwzG1R0lkM&playlistId=PLlqZM4covn1Hqp7LaICvTomOliPy4wZHp&part=snippet&maxResults=50&pageToken=${responseData1.nextPageToken}`)

    if (!response2.ok) {
      console.log(response2)
    }
    
    const responseData2 = await response2.json();
    videos = videos.concat(responseData2.items);
    
    setPlaylistVideos(videos)
    setCurrentVideo(videos[0])
  }

  function changeVideo(e) {
    const targetVideo = playlistVideos.find(video => video.id === e.target.dataset.id)
    setCurrentVideo(targetVideo)
  }

  return(
    <div className={classes.wrapper}>
      {currentVideo && (
        <>
          <div className={classes.header}>
            NOW SHOWING:<br/> 
            {currentVideo.snippet.title}
          </div>
          <div className={classes.content}>
            <div className={classes.player}>
              <div className={classes.iframeWrap}>
                <div className={classes.loading}>Loading...</div>
                <iframe src={`https://www.youtube.com/embed/${currentVideo.snippet.resourceId.videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen autoPlay></iframe>
              </div>
            </div>
            <div className={classes.list}>
              {playlistVideos.map(video => (
                <button key={`video_${video.id}`} onClick={changeVideo} className='btn btn--outline' data-id={video.id}>
                  {video.snippet.title}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default VideoPlayer