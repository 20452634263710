import React, { useEffect, useState } from 'react';
import EmailForm from './EmailForm';
import Loading from '../../components/Screens/Loading';
import classes from './Screens.module.scss';
import Modal from '../Ui/Modal';
import Countdown from './Countdown';

function Welcome(props) {
  const [modalActive, setModalActive] = useState()
  const [content, setContent] = useState('loading')

  const [countDownActive, setCountdownActive] = useState(false);

  useEffect(() => {
    if ( (props.audioReady || props.audioRedirect) && content === 'enter') {
      props.enterSite();
    }
  }, [props.audioReady, props.audioRedirect])

  // const showEmailForm = () => {
  //   setModalActive(true)
  // }

  // const closeModal = () => {
  //   setModalActive(false)
  // }

  // const emailAdded = () => {
  //   setModalActive(false)
  //   props.showEnterWarning();
  // }

  const confirmLoading = () => {
    if (props.audioReady || props.audioRedirect) {
      props.enterSite();
    } else {
      setContent('enter')
    }
  }

  // const closeCountdown = () => {
  //   setCountdownActive(false);
  // }
  
  return(
    <div className={classes.wrapper}>
      <div className={classes.wrapperOverlay}></div>
      

      <Modal role='welcome' modalActive={!props.streamActive}>
        <img className={classes.welcomeLogo} src='https://d1ebxi7lthqj9n.cloudfront.net/images/logo-main.png' />
        
        {/* {countDownActive && !props.streamActive && (
          <Countdown 
            closeCountdown={closeCountdown}
            watchStream={props.watchStream}
          />
        )} */}


        
        <div className={classes.mainWelcome} data-active={!countDownActive}>
          <hr/>
          <div className='content-block'>
            <h2 className={classes.welcomeHeader}>Sign in to Enter THE WAREHOUSE</h2>
          </div>

          <p className='content-block'>
          Congratulations on solving all our puzzles and welcome to our warehouse. Join us in
          celebrating the 20th anniversary of Meteora as we drop new and rare content for you to explore!
          </p>

          {(props.isLoading || !props.patchbayReady) && 
            <div className={classes.loadingConditional} data-status={content}>
              <Loading loadingComplete={props.loadingComplete} confirmLoading={confirmLoading} />
            </div>
          }
          {!props.isLoading && (
            <div className={classes.enterContent} data-ready={props.patchbayReady}>
              {props.audioReady
                ? 
                <Modal>
                  <button onClick={props.enterSite} className='btn'>Enter Site</button>
                </Modal>
                : <div>
                    <div className="patchbay-patch content-block" data-id="63b8bdfdbf5c295d3c310e90">Loading...</div>
                    
                  </div>
              }
            </div>
          )}
        </div>
      </Modal>

      {/* {modalActive && (
        <Modal
          modalActive={modalActive}
          closeModal={closeModal}
          allowClose={true}
        >
          <EmailForm emailAdded={emailAdded}/>
        </Modal>
      )} */}
    </div>
  )
}
  
export default Welcome;