import classes from './Voting.module.css';
import IconAngleRight from '../../components/Icons/IconAngleRight';

function Match(props) {
  return(
    <div className={classes.match} data-active={props.matchActive}>
      {/* <input
        type='radio'
        id={`match-${props.match.id}_${props.match.songs[0].id}`}
        name={`match-${props.match.id}`}
        value={props.match.songs[0].id}
        onChange={props.chooseWinner}
        data-won={props.match.songs[0].won}
        data-round={1}
        data-match={props.match.id}
      />
      <label htmlFor={`match-${props.match.id}_${props.match.songs[0].id}`}>
        {props.match.songs[0].title}
      </label> */}

      <button 
        name={`match-${props.match.id}`}
        onClick={props.chooseWinner}
        data-won={props.match.songs[0].won}
        data-round={props.round.id}
        data-match={props.match.id}
        data-song={props.match.songs[0].id}
        className={classes.songButton}
        >
        {props.match.songs[0].title}
      </button>
      
      <span>VS</span>
      
      <button 
        name={`match-${props.match.id}`}
        onClick={props.chooseWinner}
        data-won={props.match.songs[1].won}
        data-round={props.round.id}
        data-match={props.match.id}
        data-song={props.match.songs[1].id}
        className={classes.songButton}
        >
        {props.match.songs[1].title}
      </button>

      {props.round.bye &&
        <div className={classes.byeText}>("{props.round.bye.title}" gets a bye)</div>
      }

      {props.side.id !== 'side-final' && (
        <div className={classes.controls}> 
          <button className='btn btn--link' onClick={props.nextMatch}>Next <IconAngleRight /> </button>
        </div>
      )}
    </div>
  )
}

export default Match;