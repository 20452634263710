import classes from './Vinyl.module.scss'

import { Fragment } from "react"

function VinylDeluxe() {
  return(
    <Fragment>
      <h3>METEORA 20TH ANNIVERSARY EDITION DELUXE VINYL BOX SET INCLUDES:</h3>

      <div className='flex-between'>
        <div className={classes.boxSetWrap} data-size='sm'>
          <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/LP_M20_vinyl-slip_comp_v2.png' />
          <div className={classes.boxSetInfo}>
            <h3>3 LPS</h3>
            <ul>
              <li>METEORA (1 LP)</li>
              <li>LPU Rarities 2.0 (2 LP)</li>
              <li>Live Rarities 2003-2004 (1 LP)</li>
            </ul>

          </div>

          {/* <div className={classes.boxSetScrollTip}>SCROLL</div> */}
        </div>
        
        <a href='https://meteora20.lnk.to/DlxVinyl' target='_blank' rel='noreferrer' className='btn btn--outline'>ORDER NOW</a>
      </div>
    </Fragment>
  )
}

export default VinylDeluxe