import { useRef, useState } from "react"
import IconCloudUpload from "../Icons/IconCloudUpload"
import IconCloudDone from "../Icons/IconCloudDone"

function Form(props) {
  const nameRef = useRef()
  const emailRef = useRef()
  const fileRef = useRef()
  const [error, setError] = useState()
  const [hasFile, setHasFile] = useState(false)

  const [artworkSubmitted, setArtworkSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  function handleSubmit(e) {
    e.preventDefault()

    if (!nameRef.current.value || !emailRef.current.value) {
      return setError('Please add your name and email')
    }

    if (!fileRef.current || !fileRef.current.files || fileRef.current.files.length === 0) {
      return setError('Please add a file')
    }

    setError()
    setSubmitting(true)

    const formData = new FormData();
    formData.append('name', nameRef.current.value);
    formData.append('email', emailRef.current.value);

    if (fileRef.current) {
      const file = fileRef.current.files[0];
      formData.append('file_input', file);
    }

    // RECAPTCHA
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute("6LeyEyskAAAAAP3BUXKH7ynHCzWZWHdVwZEt_3eM", {action: 'submit'}).then(token => {
        formData.append("token", token);

        setSubmitting(true)

        fetch('/artwork', {
          method: 'POST',
          body: formData
        })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            setSubmitting(false)
            if (data.erro.includes('File too large')) {
              return setError('File too large. Max size 10MB')
            } else {
              return setError(data.error)
            }
          }
          
          setSubmitting(false)
          setArtworkSubmitted(true)
        })
        .catch(error => {
          setSubmitting(false)
        })
      });
    });
  }

  function checkFiles() {
    if (fileRef.current && fileRef.current.files && fileRef.current.files.length > 0) {
      setHasFile(true)
      setError()
    }
  } 

  return (
    !artworkSubmitted
      ?
      submitting
        ?
        <div><h4>UPLOADING...</h4></div>
        :
        <form onSubmit={handleSubmit}>
          <h3>Upload Your Image</h3>
          <div className='input-row'>
            <input 
              ref={nameRef}
              className='input--outline'
              type='text'
              name='name'
              placeholder='Name'
            />
          </div>
          <div className='input-row'>
            <input 
              ref={emailRef}
              className='input--outline'
              type='email'
              name='email'
              placeholder='Email'
            />
          </div>

          <div className='input-row'>
            <label htmlFor='file-upload' className="file-upload">
              <input 
                ref={fileRef}
                id='file-upload'
                type='file'
                name='image'
                placeholder='Add an image'
                onChange={checkFiles}
              />
              {hasFile
                ?
                <IconCloudDone /> 
                :
                <IconCloudUpload /> 
              }
                
              {hasFile ? 'FILE ADDED' : 'CHOOSE FILE'}
            </label>
          </div>

          <div className='input-row'>
            <button type='submit' className='btn btn--block'>SUBMIT</button>
          </div>
          <div className='input-row'>
            {error}
          </div>
        </form>
      :
      <div>
        <h3>Image Uploaded!</h3>
        <button onClick={props.closeForm} className='btn btn--block'>CLOSE</button>
      </div>
  )
} 

export default Form