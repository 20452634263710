import { useRef, useState } from 'react'
import classes from './MenuPanel.module.scss'
import IconFacebook from '../../components/Icons/IconFacebook'
import IconTwitter from '../../components/Icons/IconTwitter'
import IconInstagram from '../../components/Icons/IconInstagram'
import IconYouTube from '../../components/Icons/IconYouTube'
import IconLine from '../../components/Icons/IconLine'
import IconTumbler from '../../components/Icons/IconTumbler'
import IconSnapchat from '../../components/Icons/IconSnapchat'
import IconShizam from '../Icons/IconShazam'
import IconTikTok from '../../components/Icons/IconTikTok'

function MenuPanel(props) {
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const emailInputRef = useRef(null)

  function submitEmail() {
    console.log(emailInputRef.current.value)
    setEmailSubmitted(true)
  }

  return(
    <div className={classes.wrapper}  data-menuactive={props.menuActive}>
      <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/menu-logo.png' className={classes.logo} />
      <ul>
        <li><a href='https://www.linkinpark.com/music' target='_blank' rel='noreferr'>MUSIC</a></li>
        <li><a href='http://lprk.co/lpu' target='_blank' rel='noreferr'>LPU</a></li>
        <li><a href='http://lprk.co/youtube' target='_blank' rel='noreferr'>VIDEOS</a></li>
        <li><a href='https://store.linkinpark.com/' target='_blank' rel='noreferr'>STORE</a></li>
        <li><a href='https://hybridtheory.linkinpark.com/' target='_blank' rel='noreferr'>HYBRID THEORY</a></li>
      </ul>
      {emailSubmitted
        ?
        <div className={classes.emailForm}>THANKS FOR SUBSCRIBING!</div>
        :
        <div className={`input-group ${classes.emailForm}`}>
          <input
            ref={emailInputRef}
            className='input--outline'
            type='email'
            placeholder='email'
          />
          <button onClick={submitEmail} className='btn'>SUBSCRIBE</button>
          {/* <a href='' target='_blank' rel='noreferr'>TERMS</a> */}
        </div>
      }
      <div className={classes.icons}>
        <div className={classes.iconRow}>
          <a href='http://mikeshinoda.com/' target='_blank' rel='noreferr'>MS</a>
          <a href='http://joehahn.com/' target='_blank' rel='noreferr'>JH</a>
          <a href='https://chester.linkinpark.com/' target='_blank' rel='noreferr'>CB</a>
          <a href='https://www.instagram.com/phoenixlp/' target='_blank' rel='noreferr'>DF</a>
          <a href='https://www.instagram.com/braddelson/' target='_blank' rel='noreferr'>BD</a>
        </div>
        <div className={classes.iconRow}>
          <a href='http://www.facebook.com/linkinPark' target='_blank' rel='noreferr'><IconFacebook /></a>
          <a href='http://twitter.com/linkinpark' target='_blank' rel='noreferr'><IconTwitter /></a>
          <a href='http://instagram.com/linkinpark' target='_blank' rel='noreferr'><IconInstagram /></a>
          <a href='http://lprk.co/youtube' target='_blank' rel='noreferr'><IconYouTube /></a>
          <a href='http://line.me/R/ti/p/@linkinpark' target='_blank' rel='noreferr'><IconLine /></a>
          <a href='http://linkinpark.tumblr.com/' target='_blank' rel='noreferr'><IconTumbler /></a>
          <a href='https://www.linkinpark.com/snapchat' target='_blank' rel='noreferr'><IconSnapchat /></a>
          <a href='https://www.shazam.com/artist/33734/linkin-park' target='_blank' rel='noreferr'><IconShizam /></a>
          <a href='https://www.tiktok.com/@linkinpark' target='_blank' rel='noreferr'><IconTikTok /></a>
        </div>
      </div>
    </div>
  )
}

export default MenuPanel