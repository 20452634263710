import classes from './Video.module.scss'

function Video(props) {
  return(
    <div className={classes.content}>
      <div className={classes.iframeWrap}>
        <div className={classes.loading}>Loading...</div>
        <iframe src={props.item.video_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen autoPlay></iframe>
      </div>
    </div>
  )
}

export default Video