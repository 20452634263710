import { Fragment } from "react"
import classes from './Gallery.module.scss'
import IconOpenWindow from '../../components/Icons/IconOpenWindow'

function Gallery5(props) {
  const images = [
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_1.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_2.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_3.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_4.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_5.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_6.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_7.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_8.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_9.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_10.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_11.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_12.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_13.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_14.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_15.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_16.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_17.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_18.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_19.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_20.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_21.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_22.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_23.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_24.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_25.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_26.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_27.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_28.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_29.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_30.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_31.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_32.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_33.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_34.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_35.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_36.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_37.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_38.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_39.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_40.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_41.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_42.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_43.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_44.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_45.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-5/g-5_46.jpeg',
  ]

  return(
    <Fragment>
      <img src={images[props.index]} data-active={true} data-index={props.index} />
      <a href={images[props.index]} target='_blank' rel='noreferrer' className={classes.zoomLink}>
        Open full-size image
        <IconOpenWindow />
      </a>
    </Fragment>
  )
}

export default Gallery5