import classes from './RadioButtons.module.scss'

function RadioButtons(props) {
  return(
    <div className={classes.wrap} data-shape={props.shape}>
      {props.options.map((option, i) => (
        <div key={`option_${i}`} className={classes.option} data-color={option.value}>
          <input
            type='radio'
            id={`input-color--${props.name}-${option.value}`}
            name={props.name}
            value={option.value}
            onChange={props.handleChange}
          />
          <label htmlFor={`input-color--${props.name}-${option.value}`}>
            <div>
              {option.icon}
              <span>{option.text}</span>
            </div>
          </label>
        </div>
      ))}
    </div>
  )
}

export default RadioButtons;