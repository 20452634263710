import classes from './Voting.module.css';

function Message(props) {
  return(
    <div className={classes.message}>
      {props.children}
      
    </div>
  )
}

export default Message;