import { Fragment } from "react"
import classes from './Gallery.module.scss'
import IconOpenWindow from '../../components/Icons/IconOpenWindow'

function Gallery3(props) {
  const images = [
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_01.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_02.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_03.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_04.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_05.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_06.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_07.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_08.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_09.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_10.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_11.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_12.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_13.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_14.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_15.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_16.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_17.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_18.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_19.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_20.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_21.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_22.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_23.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_24.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_25.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_26.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_27.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_28.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_29.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_30.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_31.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_32.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_33.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_34.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_35.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_36.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_37.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_38.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_39.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_40.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_41.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_42.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_43.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_44.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_45.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_46.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_47.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_48.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_49.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_50.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_51.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_52.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_53.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_54.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_55.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_56.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_57.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_58.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_59.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_60.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_61.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_62.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_63.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_64.jpeg',
    'https://d1ebxi7lthqj9n.cloudfront.net/galleries/gallery-3/unrlsedphotos_65.jpeg',
  ]

  return(
    <Fragment>
      <img src={images[props.index]} data-active={true} data-index={props.index} />
      <a href={images[props.index]} target='_blank' rel='noreferrer' className={classes.zoomLink}>
        Open full-size image
        <IconOpenWindow />
      </a>
    </Fragment>
  )
}

export default Gallery3