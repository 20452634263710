function IconSnapchat() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="21.292" height="20" viewBox="0 0 21.292 20">
      <path d="M75,79.128c-2.9-.481-4.195-3.444-4.254-3.576,0-.007-.007-.013-.013-.02a.958.958,0,0,1-.105-.777c.165-.4.784-.586,1.192-.718.112-.033.224-.072.31-.105.724-.283,1.08-.632,1.073-1.041a.877.877,0,0,0-.645-.744h0a1.224,1.224,0,0,0-.441-.086.927.927,0,0,0-.408.086,2.424,2.424,0,0,1-.915.257.731.731,0,0,1-.356-.092c.007-.145.02-.3.026-.454l.007-.059a10.6,10.6,0,0,0-.27-4.307A5.67,5.67,0,0,0,64.929,64.1h-.053l-.369.007A5.66,5.66,0,0,0,59.252,67.5a10.577,10.577,0,0,0-.27,4.3c.013.171.02.349.033.52a.849.849,0,0,1-.415.092,2.357,2.357,0,0,1-.981-.263.786.786,0,0,0-.336-.072,1.05,1.05,0,0,0-1.034.711c-.059.31.079.751,1.067,1.146.092.033.2.066.31.105.408.132,1.021.323,1.192.718a.93.93,0,0,1-.105.777.024.024,0,0,0-.007.02c-.053.125-1.357,3.1-4.248,3.569a.443.443,0,0,0-.369.461.716.716,0,0,0,.046.2c.217.507,1.133.882,2.8,1.139a2.367,2.367,0,0,1,.151.507c.033.158.072.323.125.5a.491.491,0,0,0,.514.375,2.965,2.965,0,0,0,.481-.066,5.692,5.692,0,0,1,1.133-.132,4.991,4.991,0,0,1,.817.066,3.913,3.913,0,0,1,1.521.784A4.812,4.812,0,0,0,64.6,84.1a.567.567,0,0,0,.105-.007c.04,0,.1.007.158.007a4.789,4.789,0,0,0,2.924-1.146,3.911,3.911,0,0,1,1.528-.79,4.913,4.913,0,0,1,.81-.066,5.865,5.865,0,0,1,1.133.119,2.674,2.674,0,0,0,.481.059h.026a.483.483,0,0,0,.494-.369c.053-.171.086-.329.125-.494a1.742,1.742,0,0,1,.151-.5c1.666-.257,2.581-.632,2.8-1.133a.539.539,0,0,0,.046-.2A.466.466,0,0,0,75,79.128Z" transform="translate(-54.088 -64.1)" fill="#fff"/>
    </svg>
  )
}

export default IconSnapchat;

