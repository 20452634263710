import { useRef, useState } from 'react';
import Modal from '../../components/Ui/Modal';
import classes from './Voting.module.css';

function VoteForm(props) {
  const emailRef = useRef();
  const [error, setError] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const song = props.song.title;
    if (!email || email === '') {
      return setError('Please enter your email address');
    }

    // RECAPTCHA
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute("6LeyEyskAAAAAP3BUXKH7ynHCzWZWHdVwZEt_3eM", {action: 'submit'}).then(token => {
        fetch('/votes', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({email, song, token})
        })
        .then(response => response.json())
        .then(data => {
          props.handleVoteSubmitted();
        })
        .catch(error => console.log(error));
      })
    });
  }

  return(
    <Modal
      modalActive={props.voteFormActive}
      allowClose={true}
      closeModal={props.closeVoteForm}
    >
      <div className={classes.voteForm}>
        <h3>Enter your email to submit your vote:</h3>

        <div className={classes.voteFormSong}>
          {props.song.title}
        </div>

        <form onSubmit={handleSubmit}>
          <div className='input-row'>
            <input
              ref={emailRef}
              type='email'
              required={true}
              name='vote_email'
              placeholder='Email...'
            />
          </div>

          {error}

          <div className='input-row'>
            <button className='btn btn--block'>Submit Vote</button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default VoteForm;