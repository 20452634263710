import { useEffect, useRef, useState } from 'react'
import IconMove from '../Icons/IconMove'
import Modal from '../Ui/Modal';
import classes from './ArtWall.module.scss'
import Video from './Video';
import Gallery from './Gallery';
import RecordModel from './RecordModel';
import IconCloudDownload from '../Icons/IconCloudDownload';

let canDrag = false;
let dragging = false;
let startX = 0;
let elementX = 0;
let maxX = 0;

function ArtWall(props) {
  const scrollWrapRef = useRef(null)
  const [instructionsActive, setInstructionsActive] = useState(true)
  const [modalActive, setModalActive] = useState(false)
  const [recordActive, setRecordActive] = useState(false)
  const [currentItem, setCurrentItem] = useState();

  const items = [
    {id: 'item-1', category: 'download', title: 'METEORA|20 Art Pack', image: '/', asset_url: 'https://d1ebxi7lthqj9n.cloudfront.net/files/LP_METEORA20_ART_1.zip'},
    {id: 'item-2', category: 'gallery', title: 'BREAKING THE HABIT COLLECTION 1', image: '', gallery: 1},
    {id: 'item-3', image: '/images/P1_REDMARK.png'},
    {id: 'item-4', image: '/images/P1_CREATURE.png'},
    {id: 'item-5', image: '/images/P1_TOPDUDE.png'},
    {id: 'item-6', category: 'video', title: 'BBB SOUNDBITES', image: '/', video_url: 'https://www.youtube.com/embed/2FO8ki0mgrY'},
    {id: 'item-7', category: 'record', title: 'MR. HAHN VINYL', image: ''},
    {id: 'item-8', category:  props.finalPhase ? 'gallery' : null, title: 'BREAKING THE HABIT MUSIC VIDEO BTS PACK 2', image: '', gallery: 5},
    {id: 'item-9', category: 'video', title: 'LPU “Lying From You”', image: '/', video_url: 'https://www.youtube.com/embed/us2Jmxy0wJA '},
    {id: 'item-10', image: ''},
    {id: 'item-11', image: '/images/P2_QUOTE.png'},
    {id: 'item-12', image: '/images/P2_SILOUETTE.png'},
    {id: 'item-13', category: 'gallery', title: 'Unreleased photo Proofs | Collection 1', image: '', gallery: 3},
    {id: 'item-14', image: '/images/P2_SILOUETTE.png'},
    {id: 'item-15', image: '/images/P2_SILOUETTE.png'},
    {id: 'item-16', image: '/images/P2_SILOUETTE.png'},
    {id: 'item-17', category: 'download', title: 'Media Disc Rip', image: '/', asset_url: 'https://d1ebxi7lthqj9n.cloudfront.net/files/09_MediaDiscRip.zip'},
    {id: 'item-18', category: 'download', title: 'METEORA WALLPAPERS', image: '', asset_url: 'https://d1ebxi7lthqj9n.cloudfront.net/files/WallpapersAndSocials.zip'},
    {id: 'item-19', image: '/images/P2_SILOUETTE.png'},
    {id: 'item-20', image: '/images/P2_SILOUETTE.png'},
    {id: 'item-21', image: '/images/P2_SILOUETTE.png'},
    {id: 'item-22', image: '/images/P2_SILOUETTE.png'},
    {id: 'item-23', image: '/images/P2_SILOUETTE.png'},
    {id: 'item-24', category: 'gallery', title: 'Unreleased photo Proofs | Collection 2', image: '', gallery: 4},
    {id: 'item-25', image: '/images/P2_SILOUETTE.png'}
  ]
  useEffect(() => {
    function initDrag(e) {
      if (e.target.dataset.role === 'art-wall') {
        canDrag = true
        startX = e.clientX ? e.clientX : e.touches[0].clientX
        elementX = scrollWrapRef.current.getBoundingClientRect().left
        maxX = -scrollWrapRef.current.getBoundingClientRect().width + window.innerWidth
      }
    }
    function drag(e) {
      if (canDrag) {
        scrollWrapRef.current.style.cursor = 'grabbing'
        const currentX = e.clientX ? e.clientX : e.touches[0].clientX
        const distance = currentX - startX
        const newX = distance + elementX


        if (Math.abs(distance) > 3) {
          dragging = true
          
          if (newX <= 0 && newX >= maxX) {
            scrollWrapRef.current.style.left = `${newX}px`
          }
        }
      }
    }
    function endDrag(e) {
      setTimeout(() => {
        dragging = false
      }, 100);
      canDrag = false
      scrollWrapRef.current.style.cursor = 'grab'
    }

    window.addEventListener('mousedown', initDrag)
    window.addEventListener('touchstart', initDrag)
    window.addEventListener('mousemove', drag)
    window.addEventListener('touchmove', drag)
    window.addEventListener('mouseup', endDrag)
    window.addEventListener('touchend', endDrag)
    return () => {
      window.removeEventListener("mousedown", initDrag)
      window.removeEventListener('touchstart', initDrag)
      window.removeEventListener("mousemove", drag)
      window.removeEventListener('touchmove', drag)
      window.removeEventListener("mouseup", endDrag)
      window.removeEventListener('touchend', endDrag)
    }
  }, [])


  function closeInstructions() {
    setInstructionsActive(false)
  }

  function selectItem(e) {
    if (!dragging) {
      const item = items.find(x => x.id === e.target.dataset.item)
      setCurrentItem(item)

      if (item.category === 'record') {
        setRecordActive(true)
      } else {
        setModalActive(true)
      }
    }
  }

  function closeItem() {
    setModalActive(false)
    setRecordActive(false)
    setCurrentItem(null)
  }
 
  return(
    <div className={classes.wrapper}>
      <div ref={scrollWrapRef} className={classes.scrollWrap} data-role='art-wall'>
        <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/wall-panel_full_v3.jpg' className={classes.wall} />
        {items.map(item => (
          item.category && 
            <div key={`item_${item.id}`} className={classes.wallItem} data-item={item.id} onClick={selectItem}  data-role='art-wall'>
              <div className={classes.toolTip}>
                <div className={classes.tooltipMarkerHalo}></div>
                <div className={classes.tooltipMarker}></div>
              </div>
            </div>
        ))} 
      </div>

      {instructionsActive && (
        <div className={classes.instructions}>
          <div className={classes.instructionIcon}>
            CLICK OR DRAG
            <IconMove />
            TO NAVIGATE
          </div>
          <button className='btn' onClick={closeInstructions}>CONTINUE</button>
        </div>
      )}

      {modalActive && (
        <Modal
          modalActive={modalActive}
          closeModal={closeItem}
          currentItem={currentItem}
          allowClose={true}
          background='blue'
          role={currentItem.category}
        > 
          <h3>{currentItem.title}</h3>


          {currentItem.category === 'download' && (
            <a className={classes.assetDownload} href={currentItem.asset_url} download target='_blank' rel='noreferrer'>
              <IconCloudDownload />
              DOWNLOAD .ZIP FILE
            </a>
          )}

          {currentItem.category === 'gallery' && (
            <Gallery closeItem={closeItem} currentItem={currentItem} />
          )}

          {currentItem.category === 'video' && (
            <Video item={currentItem} closeItem={closeItem} />
          )}
        </Modal>
      )}

      {recordActive && (
        <RecordModel closeItem={closeItem} currentItem={currentItem} />
      )}
    </div>
  )
}

export default ArtWall