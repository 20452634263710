import { Fragment, useEffect, useRef, useState } from 'react';
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {Text} from 'troika-three-text'
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import Stats from 'three/addons/libs/stats.module.js';

import classes from './Scene.module.scss'
import IconAngleLeft from '../Icons/IconAngleLeft';
import IconAngleRight from '../Icons/IconAngleRight';
import ObjectControls from './ObjectControls';
import Modal from '../../components/Ui/Modal'
import VideoPlayer from './VideoPlayer';
import VinylBoxSet from './VinylBoxset';
import VinylDeluxe from './VinylDeluxe';
import CdModal from './CdModal';
import IconOpenWindow from '../Icons/IconOpenWindow';
import Countdown from '../Screens/Countdown';
import Voting from '../Voting/Index';
import VoteWrap from './VoteWrap';

const debug = false
let scene, camera, renderer, rendererCSS, modelLoader, raycaster, pointer, controls, tempVector;
let animation

let stats
let cameraPointer;
let warehouse;
let projectorScreen
let vinyl, vinylFront, vinylBack
let merchView = false;
let activeOject, nextActiveObject;
let merchZoomPosition, officeZoomPosition;
let merchUserRotate = false;
let merchReturning = false;
const merchRackItems = []
const interactiveObjects = []
const infoPanelButtons = []
const cameraTargets = []
const cameraTargetsMobile = []
const pointerTargets = []

let winnerText = [];

let luggage, passport, whiteboard;

function Index(props) {
  const sceneWrapRef = useRef(null)
  const wrapperRef = useRef(null)
  const wrapperCssRef = useRef(null)
  const [modelsLoaded, setModelsLoaded]  = useState(false)
  const [currentCamera, setCurrentCamera] = useState(1)
  const [markersEnabled, setMarkersEnabled] = useState(false)

  const [merchViewer, setMerchViewer] = useState(false)
  const [currentItem, setCurrentItem] = useState()
  const [gatefoldOpen, setGatefoldOpen] = useState(false)
  const [boxSetActive, setBoxSetActive] = useState(false)

  const [videoModalActive, setVideoModalActive] = useState(false)
  const [videoIndex, setVideoIndex] = useState(0)

  const [countdownActive, setCountdownActive] = useState(false);

  const [votingActive, setVotingActive] = useState(false);
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    animation = null
    initGraphics()
   
    const merchCover = document.querySelector('#merch-cover')

    wrapperRef.current.addEventListener( 'pointermove', onPointerMove );
    wrapperRef.current.addEventListener( 'click', handleClick );
    merchCover.addEventListener( 'pointermove', merchPointerMove );
    merchCover.addEventListener( 'mousedown', merchPointerStart );
    merchCover.addEventListener( 'mouseup', merchPointerEnd );
    merchCover.addEventListener( 'touchstart', merchPointerStart );
    merchCover.addEventListener( 'touchend', merchPointerEnd );
    window.addEventListener("resize", resize)

    

    return () => {
      window.removeEventListener("resize", resize)

      // wrapperRef.current.removeEventListener( 'pointermove', onPointerMove );
      // wrapperRef.current.removeEventListener( 'click', handleClick );
      // merchCover.removeEventListener( 'pointermove', merchPointerMove );
      // merchCover.removeEventListener( 'mousedown', merchPointerStart );
      // merchCover.removeEventListener( 'mouseup', merchPointerEnd );
      // merchCover.removeEventListener( 'touchstart', merchPointerStart );
      // merchCover.removeEventListener( 'touchend', merchPointerEnd );

      cancelAnimationFrame( animation )


      merchRackItems.length = 0
      interactiveObjects.length = 0
      infoPanelButtons.length = 0
      cameraTargets.length = 0
      cameraTargetsMobile.length = 0
      pointerTargets.length = 0
    }
  }, [])

  useEffect(() => {
    if (!props.streamActive) {
      setCountdownActive(false);
    }
  }, [props.streamActive])

  useEffect(() => {
    if (props.ui === 'scene' && !props.isLoading && modelsLoaded) {
      
      if (props.finalPhase) {
        if (window.innerWidth < 600) {
          const officeCam = warehouse.getObjectByName('camera-target_3_mobile');
          setCurrentCamera(cameraTargetsMobile.indexOf(officeCam));
          camera.targetPosition = officeCam.position;
        } else {
          const officeCam = warehouse.getObjectByName('camera-target_3');
          setCurrentCamera(cameraTargets.indexOf(officeCam));
          camera.targetPosition = officeCam.position;
        }
        const officePointer = warehouse.getObjectByName('pointer-target_3');
        cameraPointer.targetPosition = officePointer.position;
      } else {
        if (window.innerWidth < 600) {
          camera.targetPosition = cameraTargetsMobile[0].position
        } else {
          camera.targetPosition = cameraTargets[0].position
        }
      }
      

      camera.isMoving = true
      setTimeout(() => {
        camera.checkDistance = true
      }, 1000);
    }
  }, [props.ui, props.isLoading, modelsLoaded])

  function initGraphics() {
    scene = new THREE.Scene()
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)

    camera.position.set(0,1.8,5)
    camera.targetPosition = camera.position
    camera.isMoving = false
    camera.checkDistance = false

    if (debug) {
      // camera.position.set(0,40,0)
    }

    const pointerTargetGeo = new THREE.BoxGeometry(0.5,2,0.5)
    const pointerTargetMat = new THREE.MeshNormalMaterial({wireframe: true, visible: debug})
    cameraPointer = new THREE.Mesh(pointerTargetGeo, pointerTargetMat)
    scene.add(cameraPointer)
    
    renderer = new THREE.WebGLRenderer({alpha: true, antialias: true})
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.shadowMap.enabled = true
    wrapperRef.current.appendChild(renderer.domElement)

    // CSS RENDERER
    // rendererCSS = new CSS3DRenderer();
    // rendererCSS.setSize( window.innerWidth, window.innerHeight );
    // wrapperCssRef.current.appendChild( rendererCSS.domElement );
 
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;

    // stats = new Stats();
    // wrapperRef.current.appendChild( stats.dom );
    if (debug) {
      controls = new OrbitControls( camera, renderer.domElement );
    }


    // LIGHTS
    const amblight = new THREE.AmbientLight(0xFFFFFF, 0.6)
    scene.add(amblight)

    const dirLight = new THREE.DirectionalLight(0xFFFFFF, 0.6)
    dirLight.castShadow = true
    dirLight.target.position.set(0, 0, 0);
    dirLight.position.set(0, 8, 0)
    scene.add(dirLight)
    scene.add(dirLight.target);

    dirLight.shadow.bias = -0.1;
    dirLight.shadow.camera.top = 20;
    dirLight.shadow.camera.bottom = -20;
    dirLight.shadow.camera.left = -20;
    dirLight.shadow.camera.right = 20;
    dirLight.shadow.camera.near = 1;
    dirLight.shadow.camera.far = 8;

    if (debug) {
      const helper = new THREE.DirectionalLightHelper( dirLight, 2 );
      scene.add( helper );
      scene.add( new THREE.CameraHelper( dirLight.shadow.camera ) );
    }


    // RAYCASTER //
    raycaster = new THREE.Raycaster();
		pointer = new THREE.Vector2();
    tempVector = new THREE.Vector3();

    // MODELS
    let modelUrl = 'https://d1ebxi7lthqj9n.cloudfront.net/models/lp-meteora_warehouse_v13.glb';
    

    modelLoader = new GLTFLoader();
    modelLoader.load(modelUrl, gltf => {
      warehouse = gltf.scene;
      
      warehouse.traverse(node => {
        if ( node.isMesh ) { 
          node.castShadow = true;
          node.receiveShadow = true;
        }

        if (node.name.includes('camera-target')) {
          if (node.name.includes('mobile')) {
            cameraTargetsMobile.push(node)
          } else {
            cameraTargets.push(node)
          }
        } else if (node.name.includes('pointer-target')) {
          pointerTargets.push(node)
        } else if (node.name.includes('interactivemerch')) {
          node.userData.interaction = 'merch'
          
          if (node.name.includes('rack-1_right') && window.innerWidth < 600) {
            node.position.z = node.position.z + 0.47;
          }

          node.startPosition = new THREE.Vector3();
          node.startPosition.copy(node.position);
          node.targetPosition = node.position;
          
          if (node.name.includes('skateboard')) {
            node.quaternion.copy(new THREE.Quaternion(0, 0, 0.36, 0.9))
            node.zoomQuat = new THREE.Quaternion(0, 0, -0.2, 0.9)
            node.startQuaternion = new THREE.Quaternion()
            node.startQuaternion.copy(node.quaternion)
          } else if (node.name.includes('item-5_interactivemerch')) {
            node.quaternion.copy(new THREE.Quaternion(0, 0, 0, 1))
            node.zoomQuat = new THREE.Quaternion(0, 0, -0.3, 0.3)
            node.startQuaternion = new THREE.Quaternion()
            node.startQuaternion.copy(node.quaternion)
          } else if (node.name.includes('notebook')) {
            node.rotation.z = 0.6;
            node.quaternion.copy(new THREE.Quaternion(0, 0, 0.2955, 0.955))
            node.zoomQuat = new THREE.Quaternion(0, 0, 0, 1)
            node.startQuaternion = new THREE.Quaternion()
            node.startQuaternion.copy(node.quaternion)
          } else {
            node.startQuaternion = new THREE.Quaternion()
            node.startQuaternion.copy(node.quaternion)
            node.zoomQuat = node.quaternion
          }

          if (node.isGroup && node.name.includes('rack-1') || node.isGroup && node.name.includes('rack-2')) {
            merchRackItems.push(node)
          }

          interactiveObjects.push(node)
        } else if (node.name.includes('info-panel_button')) {
          node.visible = false
          interactiveObjects.push(node)
          infoPanelButtons.push(node)
        } else if (node.name.includes('click-events')) {
          interactiveObjects.push(node)
        }
      });

      merchRackItems.sort((a, b) => (a.name > b.name) ? 1 : -1)

      // MERCH
      if (window.innerWidth < 600) {
        merchZoomPosition = new THREE.Vector3(-8, 2, 0.89);
        const officeCam = warehouse.getObjectByName('camera-target_3_mobile');
        officeZoomPosition = new THREE.Vector3(officeCam.position.x - 0.5, officeCam.position.y, officeCam.position.z)

      } else {
        merchZoomPosition = new THREE.Vector3(-9.56, 1.9, 0.87)
        const officeCam = warehouse.getObjectByName('camera-target_3');
        officeZoomPosition = new THREE.Vector3(officeCam.position.x - 0.5, officeCam.position.y, officeCam.position.z)

      }

      vinyl = warehouse.getObjectByName( "vinyl-package_interactivemerch", true );

      if (vinyl) {  
        if (window.innerWidth < 600) {
          vinyl.openPosition = new THREE.Vector3(merchZoomPosition.x - 0.4, merchZoomPosition.y, merchZoomPosition.z - 0.2)
          vinyl.closedPosition = merchZoomPosition
        } else {
          vinyl.openPosition = new THREE.Vector3(vinyl.startPosition.x + 0.8, vinyl.startPosition.y + 0.45, vinyl.startPosition.z + 0.1)
          vinyl.closedPosition = merchZoomPosition
        }
        
        
        vinylFront = warehouse.getObjectByName('vinyl-package_front_interactivemerch', true )
        vinylFront.targetQuaternion = new THREE.Quaternion()
        vinylFront.targetQuaternion.copy(vinylFront.quaternion)
        vinylFront.openQuaternion = new THREE.Quaternion(0, -0.9974949866040544, 0, 0.0707372016677029)
        vinylFront.closedQuaternion = new THREE.Quaternion(0, 0, 0, 1);

        vinylBack = vinyl.getObjectByName('vinyl-package_back_interactivemerch')
      }

      luggage = warehouse.getObjectByName('luggage_click-events');
      passport = warehouse.getObjectByName('passport_click-events');

      passport.startPosition = new THREE.Vector3();
      passport.startPosition.copy(passport.position);
      passport.targetPosition = passport.position;

      passport.startQuaternion = new THREE.Quaternion();
      passport.startQuaternion.copy(passport.quaternion);
      passport.zoomQuat = passport.quaternion;
      passport.targetQuaternion = new THREE.Quaternion();
      passport.targetQuaternion.copy(passport.quaternion);

      whiteboard = warehouse.getObjectByName('whiteboard-bracket_click-events');
      

      // SCREENING ROOM
      projectorScreen = warehouse.getObjectByName( "projector-screen", true );
      if (projectorScreen) {
        const tvVideoElement = document.getElementById( `video-1` );
        tvVideoElement.play()
        const vid1Texture = new THREE.VideoTexture( tvVideoElement );
        const vid1Material = new THREE.MeshLambertMaterial( {map:  vid1Texture});
        projectorScreen.material = vid1Material
      }


      // infoPanels.forEach(node => {
      //   addCssElements(node)
      // })

      if (props.finalPhase) {
        const officeCam = warehouse.getObjectByName('camera-target_3');
        camera.position.set(officeCam.position.x + 2, officeCam.position.y, officeCam.position.z);
        cameraPointer.targetPosition = new THREE.Vector3(officeCam.position.x + 2, officeCam.position.y, officeCam.position.z);
        const officePointer = warehouse.getObjectByName('pointer-target_3');
        cameraPointer.position.copy(officePointer.position)
        cameraPointer.targetPosition = officePointer.position;
      } else {
        cameraPointer.targetPosition = pointerTargets[0].position;
      }

      scene.add(warehouse)

      updateInfoPanels()
      props.handleModelsLoaded()
      setModelsLoaded(true);

      animate()
    })
  }

  function addCssElements(node) {
    const object = new THREE.Object3D

    const width = 500;
    const height = 500;
    const scale = 0.0038

    const wrapper = document.createElement( 'div' );
    wrapper.className = 'info-window';
    wrapper.style.width = `${width}px`;
    wrapper.style.height = `${height}px`;

    const content = document.createElement( 'div' );
    content.className = 'info-window__content';
    content.innerHTML = `
      <h2>Rediscovering the meteora wall</h2>
      <p>
        What better way to celebrate Meteora than rebuilding the wall to share with y'all??
      </p>
      <p>
        It's been chopped, moved, displayed and well-loved over the years, and now we're so excited to share the original art with all our fans.
      </p>
      <button class='btn btn--outline'>EXPLORE THE WALL</button>
    `
    wrapper.appendChild(content)

    const objectCSS = new CSS3DObject( wrapper );
    objectCSS.scale.set(scale, scale, scale);
    
    object.add(objectCSS)

    var material = new THREE.MeshPhongMaterial({
      opacity	: 0.0,
      color	: new THREE.Color( 0x000000 ),
      blending: THREE.NoBlending
    });
    var geometry = new THREE.BoxGeometry( width * scale, height * scale, 0.001 )
    const cssScreen = new THREE.Mesh( geometry, material );
    cssScreen.castShadow = true;
    cssScreen.receiveShadow = true;
    object.lightShadowMesh = cssScreen
    object.add(cssScreen)
    
    object.position.copy(node.position)
    object.quaternion.copy(node.quaternion)
    scene.add(object)
  }
  

  const setTargetLocation = (e) => {
    pointer.x = ( e.clientX / renderer.domElement.clientWidth ) * 2 - 1;
    pointer.y = - ( e.clientY / renderer.domElement.clientHeight ) * 2 + 1;

    raycaster.setFromCamera( pointer, camera );
    if (warehouse) {
      const intersectedObjects = raycaster.intersectObjects(interactiveObjects);
      if (intersectedObjects.length > 0) {
        pointer.target = intersectedObjects[ 0 ];

        if (pointer.target.object.name.includes('button')) {
          pointer.target.object.visible = true;
        }
        sceneWrapRef.current.style.cursor = 'pointer'
      } else {
        pointer.target = null;

        sceneWrapRef.current.style.cursor = 'auto'
        infoPanelButtons.forEach(button => {
          button.visible = false
        })
      }
    }
  }

  const onPointerMove = (e) => {
    setTargetLocation(e)
  }

  const handleClick = (e) => {
    setTargetLocation(e)
    
    if (pointer && pointer.target && pointer.target.object) {
      const object = pointer.target.object;
      
      if (object.name === 'info-panel_button_graffiti') {
        props.openInteraction('paint')
      } else if (object.userData.interaction === 'merch') {
        viewMerch(object)
      } else if (object.name === 'info-panel_button_art-wall') {
        props.openInteraction('art-wall')
        object.visible = false
      } else if (object.name === 'info-panel_button_merch-1') {
        window.open('https://linkinpark.warnerrecords.com/', '_blank')
        object.visible = false
      } else if (object.name === 'info-panel_button_merch-2') {
        window.open('https://store.linkinpark.com/', '_blank')
        object.visible = false
      } else if (object.name.includes('info-panel_button_screening-room')) {
        object.visible = false

        if (object.name.includes('1')) {
          setVideoIndex(0)
        } else if (object.name.includes('2')) {
          setVideoIndex(1)
        } else if (object.name.includes('3')) {
          setVideoIndex(2)
        } else if (object.name.includes('4')) {
          setVideoIndex(3)
        } else if (object.name.includes('5')) {
          setVideoIndex(4)
        } else if (object.name.includes('6')) {
          setVideoIndex(0)
        }
        
        setVideoModalActive(true)
      } else if (object.name.includes('computer') || object.name.includes('headphones') || object.name.includes('mic') ) {
        setCountdownActive(true);
        props.watchStream();
      } else if (object.name.includes('tape-recorder')) {
        window.open('https://www.tiktok.com/@linkinpark/video/7196748717947374891?lang=en', '_blank')
      } else if (object.name.includes('iphone')) {
        window.open('https://linktr.ee/linkinpark', '_blank')
      } else if (object.name.includes('blank-poster')) {
        window.open('https://discord.com/channels/1066086127993368647/1099031802133368883', '_blank')
      }  else if (object.name.includes('luggage') || object.name.includes('passport')) {
        viewMerch(object);
      } else if (object.name.includes('whiteboard-bracket_click-events')) {
        setVotingActive(true);
      }
    }
  }

  function animate() {
    animation = requestAnimationFrame(animate)
    
    if (!debug) {
      camera.lookAt(cameraPointer.position)
      if (camera.isMoving) {
        cameraPointer.position.lerp(cameraPointer.targetPosition, 0.04)
        camera.position.lerp(camera.targetPosition, 0.02)
        
        if (camera.checkDistance && camera.position.distanceTo(camera.targetPosition) < 0.001) {
          camera.isMoving = false
          camera.checkDistance = false
        }
      }
    }
    
    if (activeOject && merchView && !merchReturning) {
      activeOject.position.lerp(activeOject.targetPosition, 0.09)
      
      if (!merchUserRotate) {
        activeOject.quaternion.slerp(activeOject.targetQuaternion, 0.09)
      }

      if (activeOject.name.includes('vinyl')) {
        vinylFront.quaternion.slerp(vinylFront.targetQuaternion, 0.09)
      }

      if (activeOject.position.distanceTo(activeOject.targetPosition) < 0.001) {
        merchUserRotate = true
      }
    } else if (activeOject && !merchView && merchReturning) {
      activeOject.position.lerp(activeOject.targetPosition, 0.09)
      activeOject.quaternion.slerp(activeOject.targetQuaternion, 0.09)

      if (activeOject.position.distanceTo(activeOject.targetPosition) < 0.01) {
        merchReturning = false
        if (nextActiveObject) {
          activeOject = nextActiveObject
          nextActiveObject = null
          viewMerch(activeOject)
        }
      }
    }
    
    // stats.update()
    // rendererCSS.render( scene, camera )
    renderer.render(scene, camera)
  }

  function resize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    // rendererCSS.setSize(window.innerWidth, window.innerHeight)
  }

  function changeCamera(e) {
    let targetIndex = e.target.dataset.camera;
    const direction = e.target.dataset.direction;
    console.log(targetIndex)
    if (direction && direction === 'left') {
      targetIndex = currentCamera < cameraTargets.length ? currentCamera + 1 : 1
    } else if (direction && direction === 'right') {
      targetIndex = currentCamera === 1 ? 5 : currentCamera - 1
    }

    const target = cameraTargets.find(x => x.name.includes(targetIndex))
    const targetMobile = cameraTargetsMobile.find(x => x.name.includes(targetIndex))

    const pointer = pointerTargets.find(x => x.name.includes(targetIndex))

    if (window.innerWidth < 600) {
      camera.targetPosition = targetMobile.position
    } else {
      camera.targetPosition = target.position
    }
    cameraPointer.targetPosition = pointer.position
    
    
    setCurrentCamera(parseInt(targetIndex))

    camera.isMoving = true
    setTimeout(() => {
      camera.checkDistance = true
    }, 1000);
  }

  const viewMerch = (object) => {
    if (object.name.includes('vinyl') || object.name.includes('cd')) {
      if (object.name.includes('vinyl-boxset')) {
        setCurrentItem({title: 'Super Deluxe', category: 'vinyl', url: 'https://meteora20.lnk.to/SuperDeluxe'})
      } else if (object.name.includes('vinyl-deluxe')) {
        setCurrentItem({title: 'METEORA 20TH ANNIVERSARY EDITION DELUXE VINYL BOX SET', category: 'vinyl', url: 'https://meteora20.lnk.to/DlxVinyl'})
      } else if (object.name.includes('cd')) {
        setCurrentItem({title: 'METEORA 20TH ANNIVERSARY EDITION DELUXE CD', category: 'vinyl', url: 'https://meteora20.lnk.to/3CD'})
      }
      setBoxSetActive(true)
    } else if (object.name.includes('passport') || object.name.includes('luggage')) {
      setCurrentItem({title: '', category: 'passport', url: 'https://lpunderground.com/'})
      
      activeOject = passport;
      merchView = true;
      merchReturning = false;
      setMerchViewer(true)

      activeOject.targetPosition = officeZoomPosition;
      activeOject.targetQuaternion = activeOject.zoomQuat;
    } else {
      merchView = true;
      merchReturning = false;
      merchUserRotate = false;
      
      if (object.name.includes('viny-boxset')) {
        setCurrentItem({title: 'Super Deluxe', category: 'vinyl', url: 'https://meteora20.lnk.to/SuperDeluxe'})
        activeOject = vinyl
      } else if (object.name.includes('vinyl-deluxe')) {
        setCurrentItem({title: 'METEORA 20TH ANNIVERSARY EDITION DELUXE VINYL BOX SET', category: 'vinyl', url: 'https://meteora20.lnk.to/DlxVinyl'})
      } else if (object.name.includes('METEORA 20TH ANNIVERSARY EDITION DELUXE CD')) {
        setCurrentItem({title: 'Super Deluxe', category: 'vinyl', url: 'https://meteora20.lnk.to/3CD'})
      } else if (object.name.includes('rack')) {
        if (object.name.includes('tshirt-6')) {
          setCurrentItem({title: 'Circle Spray Twofer', category: 'shirt', url: 'https://store.linkinpark.com/products/circle-spray-twofer'})
        } else if (object.name.includes('tshirt-7')) {
          setCurrentItem({title: 'Spray Box Washed Black Tee', category: 'shirt', url: 'https://store.linkinpark.com/products/spray-box-washed-black-tee'})
        } else if (object.name.includes('tshirt-8')) {
          setCurrentItem({title: 'METEORA PHOTO GRID BLACK TEE', category: 'shirt', url: 'https://store.linkinpark.com/products/meteora-photo-grid-black-tee'})
        } else if (object.name.includes('tshirt-9')) {
          setCurrentItem({title: 'Meteora Sound Board Navy Tee', category: 'shirt', url: 'https://store.linkinpark.com/products/meteora-sound-board-navy-tee'})
        } else if (object.name.includes('tshirt-10')) {
          setCurrentItem({title: 'Meteora Wall Art Black Longsleve', category: 'shirt', url: 'https://store.linkinpark.com/products/meteora-wall-art-black-long-sleeve'})
        } else if (object.name.includes('tshirt-11')) {
          setCurrentItem({title: 'METEORA COVER SPRAY NAVY TEE', category: 'shirt', url: 'https://store.linkinpark.com/products/meteora-cover-spray-navy-tee'})
        } else if (object.name.includes('tshirt-12')) {
          setCurrentItem({title: 'METEORA SPRAY RAGLAN', category: 'shirt', url: 'https://store.linkinpark.com/products/meteora-spray-raglan'})
        } else if (object.name.includes('tshirt-13')) {
          setCurrentItem({title: 'Logo Tilt Washed Black Hoodie', category: 'shirt', url: 'https://store.linkinpark.com/products/logo-tilt-washed-black-hoodie'})
        } else if (object.name.includes('tshirt-14')) {
          setCurrentItem({title: 'METEORA|20 Black Work Jacket', category: 'shirt', url: 'https://store.linkinpark.com/products/meteora-20-black-work-jacket'})
        } else if (object.name.includes('tshirt-15')) {
          setCurrentItem({title: 'Meteora Blue Spray Navy Crop Hoodie', category: 'shirt', url: 'https://store.linkinpark.com/products/meteora-blue-spray-navy-crop-hoodie'})
        } else if (object.name.includes('tshirt-16')) {
          setCurrentItem({title: 'https://store.linkinpark.com/products/meteora-drip-collage-black-hoodie', category: 'shirt', url: 'https://store.linkinpark.com/products/meteora-drip-collage-black-hoodie'})
        }
        activeOject = object.isGroup ? object : object.parent
      } else {
        activeOject = object.isGroup ? object : object.parent
        if (object.name.includes('hat-1')) {
          setCurrentItem({title: 'METEORA|20 Snapback Trucker Hat', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-20-snapback-trucker-hat'})
        } else if (object.name.includes('hat-2')) {
          setCurrentItem({title: 'METEORA LOGO DK GREY DAD HAT', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-logo-dk-grey-hat'})
        } else if (object.name.includes('skate')) {
          setCurrentItem({title: 'METEORA|20 Skate Deck', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-20-skate-deck'})
        } else if (object.name.includes('earring')) {
          setCurrentItem({title: 'METEORA|20 Earrings', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-earrings'})
        } else if (object.name.includes('keychain')) {
          setCurrentItem({title: 'METEORA|20 Black Lanyard Keychain', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-20-black-lanyard-keychain'})
        } else if (object.name.includes('pin-set')) {
          setCurrentItem({title: 'Meteora|20 Pin Set', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-20-pin-set'})
        } else if (object.name.includes('coaster')) {
          setCurrentItem({title: 'Meteora|20 Coaster Set', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-20-coaster-set'})
        } else if (object.name.includes('item-5_interactivemerch')) {
          activeOject = warehouse.getObjectByName('sweatpants-hanging_interactivemerch')
          setCurrentItem({title: 'Meteora|20 pigment Black Joggers', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-20-pigment-black-joggers'})
        } else if (object.name.includes('water-bottle')) {
          setCurrentItem({title: 'Meteora|20 Water Bottle', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-waterbottle'})
        } else if (object.name.includes('pin3')) {
          setCurrentItem({title: 'Meteora|20 Pin', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-20-pin'})
        } else if (object.name.includes('notebook')) {
          setCurrentItem({title: 'Meteora|20 Notebook', category: 'general-merch', url: 'https://store.linkinpark.com/products/meteora-notebook'})
        } 
      }
      
      if (activeOject.name.includes('rack-1')) {
        activeOject.targetPosition = new THREE.Vector3(merchZoomPosition.x - 0.5, merchZoomPosition.y - 0.03, merchZoomPosition.z)
      } else if (activeOject.name.includes('rack-2') || activeOject.name.includes('sweatpants-hanging')) {
        activeOject.targetPosition = new THREE.Vector3(merchZoomPosition.x - 0.5, merchZoomPosition.y + 0.03, merchZoomPosition.z)
      } else {
        activeOject.targetPosition = merchZoomPosition
      }
      activeOject.targetQuaternion = activeOject.zoomQuat
      setMerchViewer(true)
    }
  }

  const openGatefold = () => {
    vinylFront.targetQuaternion = vinylFront.openQuaternion;
    vinyl.targetPosition = vinyl.openPosition;

    setGatefoldOpen(true)
  }

  const closeGatefold = () => {
    vinylFront.targetQuaternion = vinylFront.closedQuaternion;
    vinyl.targetPosition = vinyl.closedPosition;

    setGatefoldOpen(false)
  }

  const returnItem = () => {
    if (currentItem && currentItem.category === 'vinyl' && gatefoldOpen) {
      closeGatefold()
      
      setTimeout(() => {
        merchView = false;
        merchReturning = true;
        activeOject.targetPosition = activeOject.startPosition
        activeOject.targetQuaternion = activeOject.startQuaternion
        setMerchViewer(false)
      }, 800);
    } else {
      merchView = false;
      merchReturning = true;
      activeOject.targetPosition = activeOject.startPosition
      activeOject.targetQuaternion = activeOject.startQuaternion
      setMerchViewer(false)
    }
  }

  // Merch rotation
  var mouseDown = false,
      mouseX = 0,
      mouseY = 0;
  const merchPointerMove = (e) => {
    if (merchView) {
      if (!mouseDown) {
        return;
      }
      e.preventDefault();
      var deltaX = e.clientX - mouseX,
          deltaY = e.clientY - mouseY;
      mouseX = e.clientX;
      mouseY = e.clientY;
      rotateItem(deltaX, deltaY);
    }
  }

  const merchPointerStart = (e) => {
    if (merchView) {
      e.preventDefault();
      mouseDown = true;
      mouseX = e.clientX;
      mouseY = e.clientY;
    }
  }
  const merchPointerEnd = (e) => {
    if (merchView) {
      e.preventDefault();
      mouseDown = false;
    }
  }

  function rotateItem(deltaX, deltaY) {
    if (activeOject && merchView && merchUserRotate && !activeOject.name.includes('passport')) {
      if (!isNaN(deltaX) && !isNaN(deltaY)) {
        if (activeOject.name.includes('skateboard')) {
          activeOject.rotation.y += deltaX / 100;
        } else {
          activeOject.rotation.y += deltaX / 100;
        }
      }
    }
  }


  function changeShirt(e) {
    let items, returnDelay;
    if (activeOject.name.includes('rack-1') || activeOject.name.includes('rack-2')) {
      items = merchRackItems
    }

    if (activeOject.position.distanceTo(activeOject.targetPosition) < 0.08) {
      const direction = e.target.dataset.direction
      
      // RETURN ITEM ALTERED
      merchView = false;
      merchReturning = true;
      activeOject.targetPosition = activeOject.startPosition
      activeOject.targetQuaternion = activeOject.startQuaternion

      let targetIndex;
      const currentIndex = items.indexOf(activeOject)
      if (direction === 'left') {
        if (currentIndex === 0) {
          targetIndex = items.length - 1
        } else {
          targetIndex = currentIndex - 1
        }
      } else if (direction === 'right') {
        if (currentIndex === items.length - 1) {
          targetIndex = 0
        } else {
          targetIndex = currentIndex + 1
        }
      }

      nextActiveObject = items[targetIndex]
    }
  }

  function updateInfoPanels() {
    if (warehouse) {
      const panelArtWall = warehouse.getObjectByName('info-panel_art-wall')
      const panelMerch = warehouse.getObjectByName('info-panel_merch')
      const panelGraffiti = warehouse.getObjectByName('info-panel_graffiti')
      const panelScreeningRoom = warehouse.getObjectByName('info-panel_screening-room')
      const merchRack1 = warehouse.getObjectByName('rack-1_right')

      if (window.innerWidth < 600) {
        panelArtWall.position.x = panelArtWall.position.x - 1.4

        panelMerch.position.z = panelMerch.position.z + 0.6
        panelMerch.rotation.z = -Math.PI / 2
        panelMerch.position.y = panelMerch.position.y + 0.5

        if (merchRack1) {
          merchRack1.position.z = merchRack1.position.z + 0.47
        }

        if (luggage && passport) {
          luggage.position.set(luggage.position.x + 2, luggage.position.y, luggage.position.z + 1.5);
          passport.position.set(passport.position.x + 2, passport.position.y, passport.position.z + 1.5);

          passport.startPosition = new THREE.Vector3();
          passport.startPosition.copy(passport.position);
          passport.targetPosition = passport.position;

          passport.startQuaternion = new THREE.Quaternion();
          passport.startQuaternion.copy(passport.quaternion);
          passport.zoomQuat = passport.quaternion;
          passport.targetQuaternion = new THREE.Quaternion();
          passport.targetQuaternion.copy(passport.quaternion);
        }

        if (panelGraffiti) {
          panelGraffiti.position.z = panelGraffiti.position.z - 2.8
          panelGraffiti.position.x = panelGraffiti.position.x - 1
        }

        if (panelScreeningRoom) {
          panelScreeningRoom.position.z -= 0.8
          panelScreeningRoom.position.x += 0.8
          panelScreeningRoom.position.y += 0.3
        }
      }
    }
  }

  function addBracketText(textContent, zOffset, yOffset) {
    

    const text = new Text();
    text.font = 'https://d1ebxi7lthqj9n.cloudfront.net/fonts/PermanentMarker-Regular.ttf';
    text.fontSize = 0.028;
    text.color = 0x000000;
    text.position.copy(whiteboard.position);
    text.rotation.y = Math.PI / 2;
    text.rotation.z = 0.02;
    text.anchorX = '50%';
    text.anchorY = '50%';
    text.position.x += 0.01;


    text.text = textContent;
    text.position.z += zOffset;
    text.position.y += yOffset;


    //round 3 dont change z

    text.sync();
    winnerText.push(text);
    scene.add(text);
  }

  function closeBoxSet() {
    setBoxSetActive(false)
  }

  function closeVideoModal() {
    setVideoModalActive(false)
  }


  const closeCountdown = () => {
    setCountdownActive(false);
  }

  const closeVoting = () => {
    setVotingActive(false);
  }

  const addWinner = (songs) => {
    if (winners.length < 11) {
      let newWinners = [...winners];
      newWinners = newWinners.concat(songs);
      setWinners(newWinners);
    }
  }

  const updateBracket = () => {
    winners.forEach((winner, index) => {
      if (index === 0) {
        addBracketText(winner.title, 0.33, 0.3);
      } else if (index === 1) {
        addBracketText(winner.title, 0.33, 0.25);
      } else if (index === 2) {
        addBracketText(winner.title, 0, 0.12);
      } else if (index === 3) {
        addBracketText(winner.title, 0, 0.07);
      } else if (index === 4) {
        addBracketText(winner.title, -0.35, 0.02);
      } else if (index === 5) {
        addBracketText(winner.title, 0.32, -0.28);
      } else if (index === 6) {
        addBracketText(winner.title, 0.32, -0.33);
      } else if (index === 7) {
        addBracketText(winner.title, 0, -0.08);
      } else if (index === 8) {
        addBracketText(winner.title, 0, -0.13);
      } else if (index === 9) {
        addBracketText(winner.title, -0.35, -0.02);
      } else if (index === 10) {
        addBracketText(winner.title, -0.6, 0);
      }
    })
  }

  const clearBracket = () => {
    setWinners([]);

    winnerText.forEach(text => {
      scene.remove(text);
    })
    winnerText.length = 0;
  }
  
  return(
    <div ref={sceneWrapRef} id='scene-wrap' className={classes.sceneWrapper} data-markers-enabled={markersEnabled}>
      <div className={classes.loading}>Loading...</div>

      <div ref={wrapperCssRef} className={classes.webglWrapper}  data-role='css'></div>
      <div ref={wrapperRef} className={classes.webglWrapper}  data-role='3d'></div>



        <Fragment>
          <button onClick={changeCamera} className={`btn btn--outline btn--circle ${classes.cameraPan}`} data-direction='left'>
            <IconAngleLeft />
          </button>
          <button onClick={changeCamera} className={`btn btn--outline btn--circle ${classes.cameraPan}`} data-direction='right'>
            <IconAngleRight />
          </button>
        </Fragment>

      <ObjectControls
        active={merchViewer}
        currentItem={currentItem}
        gatefoldOpen={gatefoldOpen}
        returnItem={returnItem}
        openGatefold={openGatefold}
        closeGatefold={closeGatefold}
        changeShirt={changeShirt}
      />
      
      {boxSetActive && currentItem && (
        <Modal
          closeModal={closeBoxSet}
          modalActive={boxSetActive}
          allowClose={true}
        > 
          {currentItem.title.includes('Super') && (
            <VinylBoxSet />
          )}
          {currentItem.title.includes('DELUXE VINYL') && (
            <VinylDeluxe />
          )}
          {currentItem.title.includes('CD') && (
            <CdModal />
          )}
        </Modal>
      )}

      {videoModalActive && (  
        <Modal
          closeModal={closeVideoModal}
          modalActive={videoModalActive}
          allowClose={true}
          role='screening-room'
        >
          <VideoPlayer videoIndex={videoIndex} />
        </Modal>
      )}

      {countdownActive && (
        <Modal
          closeModal={closeCountdown}
          modalActive={countdownActive}
          allowClose={true}
        >
          <Countdown 
            closeCountdown={closeCountdown}
            watchStream={props.watchStream}
          />
        </Modal>
      )}

      {votingActive && (
        <VoteWrap
          winners={winners}
          addWinner={addWinner}
          updateBracket={updateBracket}
          closeVoting={closeVoting}
          clearBracket={clearBracket}
        />
      )}

      <video id="video-1" className={classes.videoMaterial} crossOrigin="anonymous" autoPlay playsInline muted loop>
        <source src="https://d1ebxi7lthqj9n.cloudfront.net/videos/lp-video-clip_v2.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
    </div>
  )
}

export default Index