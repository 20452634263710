import classes from './Footer.module.scss'

function Footer() {
  return (
    <div className={classes.footer} id='footer'>
      <div>
        © 2023 Linkin&nbsp;Park. ALL RIGHTS RESERVED
      </div>
      <span> | </span>
      <a target="_blank" rel="noreferrer" href="https://www.warnerrecords.com/privacy-policy">
        Privacy Policy
      </a>
      <span> | </span>
      <a target="_blank" rel="noreferrer" href="https://www.warnerrecords.com/terms-of-use">
        Terms of Use
      </a>
      <span> | </span>
      <a target="_blank" rel="noreferrer" href="https://www.warnerrecords.com/privacy-policy#adchoices">
        Ad choices
      </a>
      <span> | </span>
      <a target="_blank" rel="noreferrer" href="https://www.wminewmedia.com/cookies-policy/">
        Cookies Policy
      </a>
    </div>
  )
}

export default Footer