import IconAngleLeft from '../Icons/IconAngleLeft'
import IconAngleRight from '../Icons/IconAngleRight'
import IconOpenWindow from '../Icons/IconOpenWindow'
import classes from './ObjectControls.module.scss'
import modalClasses from '../../components/Ui/Modal.module.scss'
import { Fragment } from 'react'


function ObjectControls(props) {
  return(
    <div className={classes.wrapper} data-active={props.active}>
      <div id='merch-cover' className={classes.merchCover} ></div>

      <button onClick={props.returnItem} className={`btn ${classes.close}`}><IconAngleLeft /> BACK</button>
      {props.currentItem && (
        <Fragment>
          {props.currentItem.category === 'shirt' && (
            <div className={classes.shirtcontrols}>
              <div className={classes.shirtBtn} onClick={props.changeShirt} data-direction='left'>
                <button className={`btn btn--outline btn--circle`} >
                  <IconAngleLeft />
                </button>
                PREV
              </div>
              <div className={classes.shirtBtn} onClick={props.changeShirt} data-direction='right'>
                <button className={`btn btn--outline btn--circle`}>
                  <IconAngleRight />
                </button>
                NEXT
              </div>
            </div>
          )}
      
        
          
          {props.currentItem.category === 'vinyl' && (  
            props.gatefoldOpen
              ?
              <button onClick={props.closeGatefold} className={`btn ${classes.vinylControls}`}>CLOSE</button>
              :
              <button onClick={props.openGatefold} className={`btn ${classes.vinylControls}`}>OPEN</button>
          )}
          
          <div className={modalClasses.box} data-role='merch'>
            <div className={modalClasses.content}>
              <h3>{props.currentItem.title}</h3>
              <a href={props.currentItem.url} rel='noreferrer' target='_blank' className='btn btn--outline'>
                {props.currentItem.category === 'passport'
                  ?<><div>Linkin Park Underground</div> <div><IconOpenWindow /></div></>
                  :<><div>{props.currentItem.category === 'vinyl' ? 'ORDER NOW' : 'SHOP NOW'}</div> <div><IconOpenWindow /></div></>
                }
                
              </a>
            </div>
          </div>
        </Fragment>
      )}
      
    </div>
  )
}

export default ObjectControls