import {Fragment, useEffect, useState} from 'react'
import Scene from './components/Scene/Index'
import Paint from "./components/Paint/Paint";
import PasswordCover from './PasswordCover'
import Welcome from './components/Screens/Welcome';
import Footer from './components/Nav/Footer'
import EnterWarning from './components/Screens/EnterWarning';
import Header from './components/Nav/Header';
import MenuPanel from './components/Nav/MenuPanel';
import ArtWall from './components/ArtWall/ArtWall';
import Modal from './components/Ui/Modal';

function App() {
  const [passwordProtected, setPasswordProtected] = useState(window.location.href.includes('heroku'))
  
  const finalPhase = true //window.location.href.includes('localhost') || window.location.href.includes('heroku');
  
  const [isLoading, setIsLoading] = useState(true)
  const [ui, setUI] = useState('welcome')

  const [interaction, setInteraction] = useState()
  const [paintCloseWarning, setPaintCloseWarning] = useState(false)

  const [audioRedirect, setAudioRedirect] = useState(false)
  const [patchbayReady, setPatchbayReady] = useState(false)
  const [audioReady, setAudioReady] = useState(false)

  const [menuActive, setMenuActive] = useState(false)

  const [phase, setPhase] = useState('phase-1')

  const [streamActive, setStreamActive] = useState(false);

  useEffect(() => {
    const url = window.location;
    const urlParams = new URLSearchParams(url.search);
    const phaseKey = urlParams.get('8GKD4');
    
    if (phaseKey === '98RI4') {
      setPhase('full')
    }
  }, []);

  useEffect(() => {
    if (!passwordProtected) {
      setupAudio();
    }
  }, [passwordProtected])


  function passwordConfirmed() {
    setPasswordProtected(false);
  }

  const setupAudio = () => {
    // Check for audio redirect immediately
    const url = window.location;
    const urlParams = new URLSearchParams(url.search);
    const accessToken = urlParams.get('access_token');
    const patchService = urlParams.get('service');
    
    if (accessToken && patchService) {
      setAudioRedirect(true)
    }

    const patchbayScript = document.createElement('script');
    patchbayScript.type = 'module';
    patchbayScript.onload = function () {
      //console.log('IM script')
    };
    patchbayScript.src = "https://js.patchbay.co/v1/embed.js?key=637c137c25c8df0ae6358716";
    document.head.appendChild(patchbayScript);

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(patchbayScript, firstScriptTag);

    document.addEventListener('audioOptOut', () => {
      enterSite();
    });
    document.addEventListener('patchSdkReady',() => {
      setPatchbayReady(true)
    })
    document.addEventListener('audioReady', () => {
      setAudioReady(true);
      enterSite();
    });
    document.addEventListener('patchPlayerInit', () => {
      // Start the experience immediately. Don't wait for player loading to complete
      // or for audio to be fully ready.
      setAudioRedirect(true)
    });
  }

  function showEnterWarning() {
    setUI('enter-warning')
  }

  function enterSite() {
    setUI('scene')
  }

  const handleModelsLoaded = () => {
    setIsLoading(false)
  }

  function openInteraction(scene) {
    setInteraction(scene)
  }
  function closeInteraction() {
    if (!paintCloseWarning && interaction === 'paint') {
      setPaintCloseWarning(true)
    } else {
      setInteraction(null)
      setPaintCloseWarning(false)
    }
  }

  function toggleMenu() {
    if (menuActive) {
      setMenuActive(false)
    } else {
      setMenuActive(true)
    }
  }

  function watchStream() {
    setStreamActive(true);
  }

  function closeStream() {
    setStreamActive(false);
  }

  return (
    passwordProtected
      ?
      <PasswordCover passwordConfirmed={passwordConfirmed} />
      :
      <Fragment>

        {!interaction && (
          <Scene 
            phase={phase}
            ui={ui}
            isLoading={isLoading}
            handleModelsLoaded={handleModelsLoaded}
            openInteraction={openInteraction}
            finalPhase={finalPhase}
            watchStream={watchStream}
            streamActive={streamActive}
          />
        )}

        {ui === 'welcome' && (
          <Welcome 
            finalPhase={finalPhase}
            isLoading={isLoading}
            patchbayReady={patchbayReady}
            audioReady={audioReady}
            showEnterWarning={showEnterWarning}
            watchStream={watchStream}
            streamActive={streamActive}
          />
        )}

        {streamActive && (
          <Modal
            allowClose={false}
            active={true}
          > 
            <div className='modal-iframe'>
              <div className='iframe-wrapper'>
                <iframe src="https://www.youtube.com/embed/UXxUHGZlhpg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
            <br/>
            <button onClick={closeStream} className='btn btn--outline btn--lg'>{ui === 'scene' ? 'CLOSE' : 'GO TO WAREHOUSE'}</button>
          </Modal>
        )}


        {ui === 'enter-warning' && (
          <EnterWarning
            enterSite={enterSite}
          />
        )}

        {interaction && interaction === 'art-wall' && (
          <ArtWall finalPhase={finalPhase} />
        )}
        {interaction && interaction === 'paint' && (
          <Paint closeInteraction={closeInteraction} paintCloseWarning={paintCloseWarning}/>
        )}

        <MenuPanel menuActive={menuActive} />
        <Header
          menuActive={menuActive}
          toggleMenu={toggleMenu}
          interaction={interaction}
          closeInteraction={closeInteraction}
        />
        <Footer />

        <div className="patchbay-player" data-id="63b8bdfdbf5c295d3c310e90"></div>
      </Fragment>
  );
}

export default App;
