function IconLine() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="20.99" height="20" viewBox="0 0 20.99 20">
      <path d="M9.918,20a.35.35,0,0,1-.283-.11.769.769,0,0,1-.053-.583l0-.023c.027-.157.149-.9.149-.9a2.02,2.02,0,0,0-.034-.949c-.115-.288-.566-.44-.924-.517A11.247,11.247,0,0,1,2.5,14.047,7.652,7.652,0,0,1,0,8.517C0,3.821,4.708,0,10.495,0S20.99,3.821,20.99,8.517a7.622,7.622,0,0,1-2.249,5.241,33.536,33.536,0,0,1-8.246,6.094A1.588,1.588,0,0,1,9.918,20ZM9.491,6.28a.2.2,0,0,0-.2.2v4.58a.2.2,0,0,0,.2.2h.745a.2.2,0,0,0,.2-.2V8.31l2.1,2.833a.182.182,0,0,0,.051.05l.012.008H12.6l.01.005h.031a.254.254,0,0,0,.053.006h.74a.2.2,0,0,0,.2-.2V6.449a.2.2,0,0,0-.057-.142.194.194,0,0,0-.14-.058H12.69a.2.2,0,0,0-.2.2V9.166L10.4,6.335a.121.121,0,0,0-.017-.021L10.369,6.3l-.011-.009h-.005l-.011-.008h-.006l-.011-.005H9.491Zm5.055-.023a.2.2,0,0,0-.2.2v4.579a.2.2,0,0,0,.2.2H17.5a.2.2,0,0,0,.2-.2v-.743a.2.2,0,0,0-.2-.2h-2V9.311h2a.2.2,0,0,0,.2-.2V8.364a.2.2,0,0,0-.2-.2h-2V7.394h2a.2.2,0,0,0,.2-.2V6.453a.2.2,0,0,0-.2-.2ZM7.995,6.248a.573.573,0,0,0-.572.572v3.837a.572.572,0,1,0,1.145,0V6.82A.573.573,0,0,0,7.995,6.248Zm-4.352.008a.2.2,0,0,0-.2.2V11.03a.2.2,0,0,0,.2.2H6.6a.191.191,0,0,0,.188-.194v-.743a.2.2,0,0,0-.2-.2h-2V6.452a.2.2,0,0,0-.2-.2Z" transform="translate(0 0)" fill="#fff"/>
    </svg>
  )
}

export default IconLine;

