import { useState } from 'react'
import BrushControl from './BrushControl'
import StencilControl from './StencilControl'
import classes from './Controls.module.scss'
import IconMove from '../Icons/IconMove'
import IconUndo from '../Icons/IconUndo'
import IconDownload from '../Icons/IconDownload'
import IconTrash from '../Icons/IconTrash'
import IconInstagram from '../Icons/IconInstagram'

function Controls(props) {
  const [flyOutActive, setFlyoutActive] = useState(true)

  function handleStencilTool() {
    props.startStencilMode()

    if (props.mode === 'stencil') {
      setFlyoutActive(flyOutActive ? false : true)
    } else {
      setFlyoutActive(true)
    }
  }

  return(
    <div className={classes.controls}>
      <BrushControl 
        mode={props.mode}
        brushType='draw'
        startDrawMode={props.startDrawMode}
        currentColor={props.currentColor}
        currentSize={props.currentSize}
        currentDensity={props.currentDensity}
        handleColorChange={props.handleColorChange}
        handleSizeChange={props.handleSizeChange}
        handleDensityChange={props.handleDensityChange}
      />
      <BrushControl 
        mode={props.mode}
        brushType='marker'
        startDrawMode={props.startMarkerMode}
        currentColor={props.currentColor}
        currentSize={props.currentSize}
        handleColorChange={props.handleColorChange}
        handleSizeChange={props.handleSizeChange}
      />
      <StencilControl 
        mode={props.mode}
        startStencilMode={props.startStencilMode}
        currentStencil={props.currentStencil}
        handleStencilChange={props.handleStencilChange}
      />
      {/* <div className={classes.control}>
        <button onClick={props.showCustomImgForm}>
          <IconInstagram />
          IMAGE
        </button>
      </div> */}
      <div className={classes.control}>
        <button onClick={props.startMoveMode} data-active={props.mode === 'move'}>
          <IconMove />
          MOVE
        </button>
      </div>
      <div className={classes.control}>
        <button onClick={props.undo}>
          <IconUndo />
          UNDO
        </button>
      </div>
      <div className={classes.control}>
        <button onClick={props.showClearWarning}>
          <IconTrash />
          CLEAR
        </button>
      </div>
      <div className={classes.control}>
        <button onClick={props.downloadImage}>
          <IconDownload />
          DOWNLOAD
        </button>
      </div>
    </div>
  )
}

export default Controls