import { useEffect, useState } from 'react';
import classes from './Countdown.module.css';
import moment from 'moment';

function Countdown(props) {
  const [streamStarted, setStreamStarted] = useState(false);
  const [days, setDays] = useState('00')
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [seconds, setSeconds] = useState('00')

  const [counter, setCounter] = useState(0)
  const [progressIsActive, setProgressActive] = useState(false);

  useEffect(() => {
    updateCountdown()
  }, [])

  useEffect(() => {
    let interval = null;
    if (progressIsActive) {
      interval = setInterval(() => {
        updateCountdown()
        setCounter(counter => counter + 1);
      }, 1000);
    } else if (!progressIsActive && counter !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [progressIsActive, counter]);

  const updateCountdown = () => {
    if (!streamStarted) {
      const targetDate = moment.utc("2023-04-10 17:30")

      const today = moment.utc()
      const daysCalc = targetDate.diff(today, 'days')
      const daysRemainder = targetDate.diff(today, 'days', true) - daysCalc
      const hoursCalc = 24 * daysRemainder
      const minutesCalc = (hoursCalc - parseInt(hoursCalc)) * 60
      const secondsCalc = (minutesCalc - parseInt(minutesCalc)) * 60

      function formatNumber(number) {
        return parseInt(number) < 10 ? `0${parseInt(number)}` : parseInt(number)
      }

      if (today.isSameOrAfter(targetDate)) {
        setStreamStarted(true);
      } else {
        setDays(formatNumber(daysCalc))
        setHours(formatNumber(hoursCalc))
        setMinutes(formatNumber(minutesCalc))
        setSeconds(formatNumber(secondsCalc))
        setProgressActive(true)

        // alarmText.text = `${formatNumber(daysCalc)}:${formatNumber(hoursCalc)}:${formatNumber(minutesCalc)}:${formatNumber(secondsCalc)}`
      }
    }
  }


  return(
    
    streamStarted
      ?
      <>
        <div className={classes.heading}>THE GLOBAL FAN Q&A LIVESTREAM HAS ENDED:</div>
        <div className={classes.btnGroup}>
          <button onClick={props.watchStream} className='btn btn--white btn--lg'>WATCH REPLAY</button>
          <button onClick={props.closeCountdown} className='btn btn--outline btn--lg'>GO TO WAREHOUSE</button>
        </div>
      </>
      :
      <>
        <div className={classes.heading}>COUNTDOWN GLOBAL FAN Q&A LIVESTREAM:</div>

        <div className={classes.timer}>
          <div className={classes.digit}>
            <div className={classes.timerNumber}>{days}</div> 
            <div className={classes.timerLabel}>DAYS</div>
          </div>
          <div className={classes.spacer}>:</div>
          <div className={classes.digit}>
            <div className={classes.timerNumber}>{hours}</div> 
            <div className={classes.timerLabel}>HOURS</div>
          </div>
          <div className={classes.spacer}>:</div>
          <div className={classes.digit}>
            <div className={classes.timerNumber}>{minutes}</div> 
            <div className={classes.timerLabel}>MINS</div>
          </div>
          <div className={classes.spacer}>:</div>
          <div className={classes.digit}>
            <div className={classes.timerNumber}>{seconds}</div> 
            <div className={classes.timerLabel}>SECS</div>
          </div>
        </div>

        <div className={classes.btnGroup}>
          <a className='btn btn--outline btn--lg' href='http://lprk.co/livestream' rel='noreferrer' target='_blank'>Set A Reminder</a>
          <button onClick={props.closeCountdown} className='btn btn--white btn--lg'>GO TO WAREHOUSE</button>
        </div>
      </>
      
  )
}

export default Countdown;