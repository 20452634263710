import { useState } from "react";
import classes from './Voting.module.css';
import Match from "./Match";
import Message from "./Message";
import VoteForm from "./VoteForm";


function Index(props) {
  const [messageActive, setMessageActive] = useState(true);
  const [error, setError] = useState();

  const [sideAWinner, setSideAWinner] = useState();
  const [sideBWinner, setSideBWinner] = useState();
  const [matchActive, setMatchActive] = useState(false);

  const [voteFormActive, setVoteFormActive] = useState(false);
  const [voteSubmitted, setVoteSubmitted] = useState(false);
  const [finalWinner, setFinalWinner] = useState();;

  const [sideA, setSideA] = useState({
    id: 'side-a',
    title: 'Side A',
    rounds: [
      {
        id: 'side-a_round-1',
        title: 'Round 1',
        matches: [
          {
            id: 'side-a_round-1_match-1',
            title: 'Match 1',
            songs: [
              {id: 'song-1', title: "Lost", won: false},
              {id: 'song-2', title: 'Fighting Myself', won: false}
            ]
          },
          {
            id: 'side-a_round-1_match-2',
            title: 'Match 2',
            songs: [
              {id: 'song-3', title: 'More The Victim', won: false},
              {id: 'song-4', title: 'Massive', won: false}
            ]
          },
          {
            id: 'side-a_round-1_match-3',
            title: 'Match 3',
            songs: [
              {id: 'song-5', title: 'Healing Foot', won: false},
              {id: 'song-6', title: "A6 - Meteora|20 Demo", won: false}
            ]
          }
        ]
      }
    ]
  });

  const [sideB, setSideB] = useState({
    id: 'side-b',
    title: 'Side B',
    rounds: [
      {
        id: 'side-b_round-1',
        title: 'Round 1',
        matches: [
          {
            id: 'side-b_round-1_match-1',
            title: 'Match 1',
            songs: [
              {id: 'song-7', title: 'Husky - Hit The Floor Demo', won: false},
              {id: 'song-8', title: 'Interrogation - Easier To Run Demo', won: false}
            ]
          },
          {
            id: 'side-b_round-1_match-2',
            title: 'Match 2',
            songs: [
              {id: 'song-9', title: 'Plaster 2 - Figure.09 Demo', won: false},
              {id: 'song-10', title: "Shifter- From The Inside Demo", won: false}
            ]
          },
          {
            id: 'side-b_round-1_match-3',
            title: 'Match 3',
            songs: [
              {id: 'song-11', title: 'Wesside', won: false},
              {id: 'song-12', title: 'Resolution', won: false}
            ]
          }
        ]
      }
    ]
  });

  const [sideFinal, setSideFinal] = useState({
    id: 'side-final',
    title: 'Top Song',
    rounds: [
      {
        id: 'side-final_round-1',
        title: 'Final Round',
        matches: []
      }
    ]
  });

  const [currentSide, setCurrentSide] = useState(sideA);
  const maxRounds = 3// total_songs / two_sides / two_songs_per_match ;
  const [currentRound, setCurrentRound] = useState(currentSide.rounds[0])
  const [currentMatch, setCurrentMatch] = useState(currentRound.matches[0]);

  const chooseWinner = (e) => {
    setError();
    const clone = {...currentSide};
    
    const round = clone.rounds.find(round => round.id === currentRound.id);
    const match = round.matches.find(match => match.id === e.target.dataset.match);
    const winner = match.songs.find(song => song.id === e.target.dataset.song);
    const loser = match.songs.find(song => song.id !== e.target.dataset.song);
    winner.won = true;
    loser.won = false;
    
    if (currentSide.id === sideA.id) {
      setSideA(clone);
    } else {
      setSideB(clone);
    }
  }

  const nextMatch = () => {
    const hasWinner = currentMatch.songs.find(song => song.won === true);

    if (hasWinner) {
      setMatchActive(false);

      setTimeout(() => {
        const currentIndex = currentRound.matches.indexOf(currentMatch);
      
        if (currentIndex < currentRound.matches.length - 1) {
          setCurrentMatch(currentRound.matches[currentIndex + 1]);
        } else {
          nextRound();
        }

        setMatchActive(true);
      }, 1000);
      
    } else {
      setError('Please select a winner for this match.')
    }
  }

  const nextRound = () => {
    if (currentSide.rounds.length === maxRounds) {
      if (currentSide.id === sideA.id) {
        const finalRound = sideA.rounds[sideA.rounds.length - 1];
        const winningSong = finalRound.matches[0].songs.find(song => song.won === true);

        setSideAWinner(winningSong);
        setMessageActive(true);

        setMatchActive(false);
        setCurrentSide(sideB);
        setCurrentRound(sideB.rounds[0]);
        setCurrentMatch(sideB.rounds[0].matches[0]);
        
        let songToSend;
        if (winningSong.title.includes('-')) {
          let formattedSong = structuredClone(winningSong);
          formattedSong.title = formattedSong.title.split("-")[0] + "- Demo";
          songToSend = formattedSong;
        } else {
          songToSend = winningSong;
        }
        props.addWinner([songToSend]);
      } else {
        const finalRoundA = sideA.rounds[sideA.rounds.length - 1];
        const winningSongA = finalRoundA.matches[0].songs.find(song => song.won === true);

        const finalRoundB = sideB.rounds[sideB.rounds.length - 1];
        const winningSongB = finalRoundB.matches[0].songs.find(song => song.won === true);

        setSideBWinner(winningSongB);
        setMessageActive(true);
        
        setMatchActive(false);
        const clone = {...sideFinal};
        winningSongA.won = false;
        winningSongB.won = false;
        clone.rounds[0].matches.push(
          {
            id: 'final-match',
            title: 'Match 1',
            songs: [winningSongA, winningSongB]
          }
        )

        setSideFinal(clone)

        setCurrentSide(sideFinal);
        setCurrentRound(sideFinal.rounds[0]);
        setCurrentMatch(sideFinal.rounds[0].matches[0]);
        
        let songToSend;
        if (winningSongB.title.includes('-')) {
          let formattedSong = structuredClone(winningSongB);
          formattedSong.title = formattedSong.title.split("-")[0] + "- Demo";
          songToSend = formattedSong
        } else {
          songToSend = winningSongB;
        }
        props.addWinner([songToSend]);
      }
    } else {
      // Collect the winners
      let winners = [];
      currentRound.matches.forEach(match => {
        const winner = match.songs.find(x => x.won === true);
        winner.won = false;
        winners.push(winner);
      });

      if (currentRound.bye) {
        winners.push(currentRound.bye)
      }

      // Define the target round
      const clone = {...currentSide};
      const nextRound = {id: `${clone.id}_round-${clone.rounds.length + 1}`, title: `Round ${clone.rounds.length + 1}`};
      const nextMatches = [];
      let bye;

      // Check if there is an uneven number of winners and give a random winner a bye
      if (winners.length % 2 > 0) {
        let winnersShuffled = winners;
        for (let i = winnersShuffled.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [winnersShuffled[i], winnersShuffled[j]] = [winnersShuffled[j], winnersShuffled[i]];
        }

        // Define the song that gets a bye and remove it from the possible match selection
        bye = winnersShuffled[0];
        winners.splice(0, 1);
      }

      // Create matches for the remaining winners
      for (let i = 0; i < winners.length; i += 2) {
        nextMatches.push({
          id: `round-${currentSide.rounds.length + 1}_match-${i + 1}`,
          title: `Match ${i + 1}`,
          songs: [winners[i], winners[i + 1]]
        })
      }
      
      // populate next round of matches and then set it as the current round
      nextRound.matches = nextMatches;
      if (bye) {
        nextRound.bye = bye;
      }
      clone.rounds.push(nextRound);

      if (currentSide.id === sideA.id) {
        setSideA(clone);
      } else {
        setSideB(clone);
      }

      setCurrentRound(nextRound);
      setCurrentMatch(nextRound.matches[0]);
      
      let songToSend1;
      if (nextRound.matches[0].songs[0].title.includes('-')) {
        let formattedSong1 = structuredClone(nextRound.matches[0].songs[0]);
        formattedSong1.title = formattedSong1.title.split("-")[0] + "- Demo";
        songToSend1 = formattedSong1;
      } else {
        songToSend1 = nextRound.matches[0].songs[0];
      }

      let songToSend2;
      if (nextRound.matches[0].songs[1].title.includes('-')) {
        let formattedSong2 = structuredClone(nextRound.matches[0].songs[1]);
        formattedSong2.title = formattedSong2.title.split("-")[0] + "- Demo";
        songToSend2 = formattedSong2;
      } else {
        songToSend2 = nextRound.matches[0].songs[1];
      }
      props.addWinner([songToSend1, songToSend2]);
    }
  }

  const closeMessage = () => {
    setMessageActive(null);

    if (!matchActive) {
      setTimeout(() => {
        setMatchActive(true);
      }, 300);
    }
  }

  const openVoteForm = () => {
    const hasWinner = currentMatch.songs.find(song => song.won === true);

    if (hasWinner) {
      let songToSend;
      if (hasWinner.title.includes('-')) {
        let formattedSong = structuredClone(hasWinner);
        formattedSong.title = hasWinner.title.split("-")[0] + "- Demo";
        songToSend = formattedSong;
      } else {
        songToSend = hasWinner;
      }
      props.addWinner([songToSend]);
      setFinalWinner(hasWinner);
      setVoteFormActive(true);
    } else {
      setError('Please select a winner for this match.')
    }
  }

  const closeVoteForm = () => {
    setVoteFormActive(false);
  }

  const handleVoteSubmitted = () => {
    closeVoteForm();
    setMessageActive(true);
    setVoteSubmitted(true);

    props.updateBracket();
  }
  
  return(
    <div className={classes.wrapper}>
      <div className={classes.ballot}>
        {/* progress */}
        {!messageActive && (
          <div className={classes.progress}>
            <div className={classes.progressTitle}>METEORA | {currentSide.title}</div>
            {currentRound.title} - {currentMatch.title} of {currentRound.matches.length}
          </div>
        )}
        
        {messageActive
          ? 
            <Message messageActive={messageActive}>
              {sideAWinner && !sideBWinner && !voteSubmitted && (
                <>
                  <h2>{sideAWinner.title} wins Side A!</h2>
                  <p>
                    Continue to Side B and choose your picks for the final matchup.
                  </p>
                  <button className='btn btn--white' onClick={closeMessage}>Continue</button>
                </>
              )}
              {sideAWinner && sideBWinner && !voteSubmitted && (
                <>
                  <h2>{sideBWinner.title} wins Side B!</h2>
                  <p>
                    Time for the final matchup to determine the top song on Meteora!
                  </p>
                  <button className='btn btn--white' onClick={closeMessage}>Continue</button>
                </>
              )}
              {!sideAWinner && !sideBWinner && !voteSubmitted && (
                <>
                  <h2>Song Voting</h2>
                  <p>
                    Click your favorite song in each matchup of the bracket to vote for your top song from Meteora.
                  </p>
                  <button className='btn btn--white' onClick={closeMessage}>Continue</button>
                </>
              )}
              {voteSubmitted && (
                <>
                  <h2>Vote Submitted!</h2>
                  <p>
                    <button onClick={props.closeVoting} className="btn">Back to Warehouse</button>
                  </p>
                </>
              )}
            </Message>
          : 
          <Match 
            side={currentSide}
            round={currentRound}
            match={currentMatch}
            chooseWinner={chooseWinner}
            matchActive={matchActive} 
            nextMatch={nextMatch}
          />
        }
          
        
        {error}
        {/* controls */}
        {!messageActive && !voteSubmitted && (
          <div className={classes.controls}> 
            {currentSide.id === 'side-final' && (
              <button onClick={openVoteForm} className="btn">Submit Your Vote</button>
            )}
          </div>
        )}
      </div>

      {finalWinner && (
        <VoteForm
          handleVoteSubmitted={handleVoteSubmitted}
          voteFormActive={voteFormActive}
          song={finalWinner}
          closeVoteForm={closeVoteForm}
        />
      )}
    </div>
  )
}

export default Index;