import classes from './Vinyl.module.scss'

import { Fragment } from "react"

function CdModal() {
  return(
    <Fragment>
      <h3>METEORA 20TH ANNIVERSARY EDITION DELUXE CD INCLUDES:</h3>

      <div className='flex-between'>
        <div className={classes.boxSetWrap} data-size='sm'>
          <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/3-cd.png' />
          <div className={classes.boxSetInfo}>
            <h3>3 CDS</h3>
            <ul>
              <li>Meteora (Inc. bonus track: the original Andy Wallace mix of “Lost”)</li>
              <li>LPU Rarities 2.0</li>
              <li>Live Rarities 2003-2004</li>
            </ul>

          </div>

          {/* <div className={classes.boxSetScrollTip}>SCROLL</div> */}
        </div>
        
        <a href='https://meteora20.lnk.to/3CD' target='_blank' rel='noreferrer' className='btn btn--outline'>ORDER NOW</a>
      </div>
    </Fragment>
  )
}

export default CdModal