import { useEffect, useRef, useState } from 'react'
import { fabric } from "fabric";
import classes from './Paint.module.scss'
import Controls from './Controls';
import Modal from '../Ui/Modal';
import IconFacebook from '../Icons/IconFacebook'
import IconTwitter from '../Icons/IconTwitter'
import IconFullScreen from '../Icons/IconFullScreen'
import IconSquare from '../Icons/IconSquare'
import IconPortrait from '../Icons/IconPortrait'
import Form from './Form'
import RadioButtons from '../Ui/Forms/RadioButtons';
import CustomImgForm from './CustomImgForm';

let canvas, sprayBrush, markerBrush;
let pointerStart = 0
let isDrawing = false
const distanceThreshold = 100
let pressureApplied = 0
let group;
const densityFactor = 3
let dripTemp = []
// let activeObjects = []

function Paint(props) {
  const containerRef = useRef()
  const canvasWrapRef = useRef()
  const canvasRef = useRef()
  const cursorRef = useRef()

  const [layoutPicker, setLayoutPicker] = useState(true)
  const [layout, setLayout] = useState('full')
  const layoutRef = useRef()

  const [modalActive, setModalActive] = useState(false)
  const [clearWarningActive, setClearWarningActive] = useState(false)
  const [shareActive, setShareActive] = useState(false)
  const [formActive, setFormActive] = useState(false)

  const [mode, setMode] = useState('draw')
  const modeRef = useRef('draw')

  const [currentColor, setCurrentColor] = useState('#000000')
  const colorRef = useRef('#000000')
  const [currentSize, setCurrentSize] = useState(20)
  const sizeRef = useRef(20)
  const [currentDensity, setCurrentDensity] = useState(40)
  const densityRef = useRef(40)

  const [currentStencil, setCurrentStencil] = useState('1')
  const stencilRef = useRef('1')

  const [customImgForm, setCustomImgForm] = useState(false)

  useEffect(() => {
    initFabric()
    window.addEventListener('resize', setSize)

    return () => {
      window.removeEventListener("resize", setSize)
      dripTemp.length = 0
    }
  }, [])

  useEffect(() => {
    if (props.paintCloseWarning) {
      openModal()
    }
  }, [props.paintCloseWarning])

  function moveCursor(e) {
    if (modeRef.current === 'draw') {
      cursorRef.current.style.left = `${e.clientX - 50}px`
      cursorRef.current.style.top = `${e.clientY - 15}px`
    } else if (modeRef.current === 'stencil') {
      cursorRef.current.style.left = `${e.clientX - 70}px`
      cursorRef.current.style.top = `${e.clientY - 70}px`
    }
  }

  function initFabric() {
    canvas = new fabric.Canvas('canvas', {
      selection: false,
      isDrawingMode: true
    });
    setSize()

    canvas.on('mouse:down', (event) => {
      pointerStart = event.e.clientX
      
      if (!group && modeRef.current === 'draw') {
        isDrawing = true
        group = new fabric.Group()
        canvas.add(group)
      }
    })

    canvas.on('mouse:move', (event) => {
      if (!window.Modernizr.touchevents) {
        moveCursor(event.e)
      }

      if (isDrawing && modeRef.current === 'draw') {
        const changeX = Math.abs(pointerStart - event.pointer.x)
        
        if (changeX > distanceThreshold) {
          pointerStart = event.pointer.x
          pressureApplied = 0
        } else {
          pressureApplied += 0.1
          if (pressureApplied >= 6) {
            pressureApplied = 0
            
            addDrips(event.pointer.x, event.pointer.y)
          }
        }
      }
    })

    canvas.on('mouse:up', e => {
      if (modeRef.current === 'stencil') {
        addImage(e.pointer.x, e.pointer.y)
      }
      if(modeRef.current === 'draw') {
        
      } 

      if (isDrawing) {
        const objects = canvas.getObjects()
        const lastObject = objects[objects.length - 1]
        canvas.remove(lastObject)
        group.addWithUpdate(lastObject)
        dripTemp.forEach(object => {
          canvas.remove(object)
          group.addWithUpdate(object)
        })
        group = null
        dripTemp.length = 0
      }

      isDrawing = false
      pressureApplied = 0
    })

    // Select and delet
    // canvas.on('selection:created', e => {
    //   activeObjects = e.selected
    // })
    // canvas.on('selection:updated', e => {
    //   activeObjects = e.selected
    // })
    // canvas.on('selection:cleared', e => {
    //   activeObjects.length = 0
    // })

    sprayBrush = new fabric.SprayBrush(canvas)
    sprayBrush.randomOpacity = true
    sprayBrush.density = currentDensity
    // sprayBrush.shadow = new fabric.Shadow({
    //   blur: 2,
    //   offsetX: 0,
    //   offsetY: 0,
    //   affectStroke: true
    // });

    markerBrush = new fabric.PencilBrush(canvas)
    markerBrush.strokeLineCap = 'butt'
    // markerBrush.strokeLineJoin = 'miter'
    // markerBrush.strokeMiterLimit = 20

    canvas.freeDrawingBrush = sprayBrush
    canvas.freeDrawingBrush.width = 20
    canvas.freeDrawingCursor = 'grab'

    // Get an item by index
    canvas.item(0)
    // Get all items
    canvas.getObjects()
  }

  function addImage(x,y) {
    group = new fabric.Group()
    const stencilScale = 0.3
    fabric.Image.fromURL(`/images/paint/stencils/stencil-${stencilRef.current}.png`, image => {
      image.scale(stencilScale)
      image.left = x - (image.width * stencilScale / 2)
      image.top = y - (image.height * stencilScale / 2)
      
      group.addWithUpdate(image);
      if (stencilRef.current === '1') {
        addDrips(x, y)
        addDrips(x - (image.width * stencilScale / 2) + 45, y + (image.height * stencilScale / 2) - 60)
      } else if (stencilRef.current === '2') {
        addDrips(x - (image.width * stencilScale / 2) + 45, y + (image.height * stencilScale / 2) - 130)
      } else if (stencilRef.current === '3') {
        addDrips(x - (image.width * stencilScale / 2) + 85, y + (image.height * stencilScale / 2) - 40)
      }
      
      dripTemp.forEach(object => {
        canvas.remove(object)
        group.addWithUpdate(object)
      })
      canvas.add(group)
      group = null
      dripTemp.length = 0

      disableTransform()
    });
  }

  function disableTransform() {
    canvas.selection = false
    const allObjects = canvas.getObjects();

    allObjects.forEach((object) => {
      object.lockMovementX = true
      object.lockMovementY = true
      object.lockScalingX = true
      object.lockScalingY = true
      object.lockRotation = true
      object.hasBorders = false
      object.hasControls = false
    })
  }

  function enableTransform() {
    canvas.selection = true
    const allObjects = canvas.getObjects();

    allObjects.forEach((object) => {
      object.lockMovementX = false
      object.lockMovementY = false
      object.lockScalingX = false
      object.lockScalingY = false
      object.lockRotation = false
      object.hasBorders = true
      object.hasControls = true
    })
  }

  function addDrips(x,y) {
    let dripScale;
    if (modeRef.current === 'draw') {
      dripScale = sizeRef.current / 200;
    } else {
      dripScale = 0.1
    }
    
    const spot = new fabric.Path('m22.84,180.278C6.545,162.85-1.707,139.345.295,115.57c1.648-19.571,7.081-34.344,7.081-34.344C20.378,42.194,43.336,23.702,43.336,23.702c13.335-12.675,26.175-18.736,35.87-21.616,8.968-2.664,18.477-2.735,27.549-.446,19.57,4.938,29.43,13.613,29.43,13.613,21.146,13.546,36.001,42.482,43.497,60.087,5.153,12.102,7.893,25.13,7.656,38.281-.374,20.763-6.635,35.804-11.279,44.165-2.056,3.702-4.726,6.999-7.682,10.031-9.493,9.735-12.103,27.085-12.701,39.058h-18.759c-1.234-4.121-4.604-7.373-8.86-8.074-4.78-.788-8.197,1.857-8.29,8.074h-15.137c-1.866-3.571-4.992-6.398-8.88-7.835l-.161-.059c-5.177-1.914-10.78-2.205-16.183-1.074-5.273,1.103-9.672.863-11.861.614-.75-.085-5.166.172-6.585,1.948-1.514,1.896-2.544,4.09-3.109,6.407h-15.497c-.083-.455-.16-.911-.246-1.366-.487-2.556-1.574-4.957-3.202-6.987-4.603-5.738-10.553-12.349-16.064-18.243Z')
    spot.scale(dripScale)
    spot.fill = modeRef.current === 'stencil' ? '#000000' : colorRef.current
    spot.set({left: x - (spot.width * dripScale / 2), top: y - (spot.height * dripScale / 2)})
    canvas.add(spot)
    dripTemp.push(spot)
    
    const drips = new fabric.Path('m126.027,58.448c2.636,8.652-11.837,14.039-14.456,5.444-5.694-18.686-1.954-40.131-5.301-59.087-.301-1.702-.864-3.317-1.642-4.805h15.137c-.005.362-.004.732.013,1.119,0,0-.002,0-.001,0,2.025,16.506.587,38.745,6.25,57.329Zm-68.859,49.639c.792-33.8,4.698-67.936.322-101.53-.287-2.205-.158-4.432.36-6.557h-15.497c6.599,36.196,1.547,73.54.772,110.045-.844,39.813,1.062,79.651,5.576,119.213.432,3.786,2.909,6.957,7,7,3.454.036,7.428-3.246,7-7-4.588-40.211-6.481-80.707-5.533-121.171ZM155.675,0h-18.759c.034.112.072.222.102.335.374,1.394.876,3.052,1.543,4.906,2.54,7.06,3.959,14.483,4.274,21.979,1.047,24.921-.194,50.004-.693,74.864-.712,35.458-1.067,70.923-1.066,106.389,0,20.219.12,40.437.349,60.654.102,9.003,14.102,9.026,14,0-.408-36.126-.459-72.256-.135-108.383.324-36.024,1.594-72.04,1.835-108.061.096-14.397-.306-28.753-1.56-43.06-.065-2.312-.086-5.676.111-9.623ZM.626,110.625c-.346,0-.626.28-.626.626s.28.626.626.626.626-.28.626-.626-.28-.626-.626-.626Zm186.1,84.061c-.346,0-.626.28-.626.626s.28.626.626.626.626-.28.626-.626-.28-.626-.626-.626Z')
    drips.scale(dripScale)
    drips.scaleY = 0.0
    drips.fill = modeRef.current === 'stencil' ? '#000000' : colorRef.current
    drips.set({left: spot.left, top: spot.aCoords.bl.y -1})
    canvas.add(drips)
    dripTemp.push(drips)

    drips.animate('scaleY', '+=0.15', {
      onChange: canvas.renderAll.bind(canvas),
      duration: 2500,
      easing: fabric.util.ease.easeOutSine
    })
  }

  function removeItem(item) {
    // Remove item by passing element
    canvas.remove(item)
  }

  function removeLastObject() {
    const totalObjects = canvas.getObjects().length
    if (totalObjects > 0) {
      canvas.remove(canvas.item(totalObjects - 1))
    }
  }

  function setSize() {
    const wrapperBounds = containerRef.current.getBoundingClientRect()
    const canvasBounds = canvasWrapRef.current.getBoundingClientRect()

    const wrapperWidth = wrapperBounds.width
    const wrapperHeight = wrapperBounds.height
    let canvasWidth = canvasBounds.width
    let canvasHeight = canvasBounds.height

    if (layoutRef.current === 'square') {
      let size;
      if (window.innerWidth > window.innerHeight) {
        size = window.innerHeight - 100
      } else {
        size = window.innerWidth - 100
      }

      canvasWrapRef.current.style.width = `${size}px`
      canvasWrapRef.current.style.height = `${size}px`
      canvasWidth = size
      canvasHeight = size
    } else if (layoutRef.current === 'portrait') {
      let height, width;
      if (window.innerWidth > window.innerHeight) {
        height = window.innerHeight - 100
        width = height * 0.56
      } else {
        width = window.innerWidth - 180
        height = width / 0.56

        if (height > window.innerHeight) {
          height = window.innerHeight - 180
          width = height * 0.56
        }
      }

      canvasWrapRef.current.style.width = `${width}px`
      canvasWrapRef.current.style.height = `${height}px`
      canvasWidth = width
      canvasHeight = height
    }

    canvas.setDimensions({width: canvasWidth, height: canvasHeight})
    
    const imgWidth = 2000
    const imgHeight = 1333
    var canvasAspect = wrapperWidth / wrapperHeight;
    var imgAspect = imgWidth / imgHeight;
    var left, top, scaleFactor;

    if (canvasAspect >= imgAspect) {
        var scaleFactor = wrapperWidth / imgWidth;
        left = 0;
        top = -((imgHeight * scaleFactor) - wrapperHeight) / 2;
    } else {
        var scaleFactor = wrapperHeight / imgHeight;
        top = 0;
        left = -((imgWidth * scaleFactor) - wrapperWidth) / 2;

    }
    canvas.setBackgroundImage('./images/paint/concrete-bg_1.jpg', canvas.renderAll.bind(canvas), {
      top: top,
      left: left,
      originX: 'left',
      originY: 'top',
      scaleX: scaleFactor,
      scaleY: scaleFactor
    });
  }

  function startDrawMode() {
    canvas.freeDrawingBrush = sprayBrush
    canvas.isDrawingMode = true
    setMode('draw')
    modeRef.current = 'draw'
    canvas.freeDrawingBrush.width = currentSize
    canvas.freeDrawingBrush.color = currentColor
    disableTransform()
  }

  function startMarkerMode() {
    canvas.freeDrawingBrush = markerBrush
    canvas.isDrawingMode = true
    setMode('marker')
    modeRef.current = 'draw'
    canvas.freeDrawingBrush.width = currentSize
    canvas.freeDrawingBrush.color = currentColor
    disableTransform()
  }

  function startMoveMode() {
    canvas.isDrawingMode = false
    setMode('move')
    modeRef.current = 'move'
    enableTransform()
  }

  function startStencilMode() {
    canvas.isDrawingMode = false
    setMode('stencil')
    modeRef.current = 'stencil'
    disableTransform()
    // canvas.defaultCursor = `url("/images/paint/stencils/stencil-1_cursor.png"), auto`
  }

  function handleColorChange(e) {
    setCurrentColor(e.target.value)
    colorRef.current = e.target.value
    canvas.freeDrawingBrush.color = e.target.value
  }

  function handleSizeChange(e) {
    const size = parseInt(e.target.value)
    setCurrentSize(size)
    sizeRef.current = size
    canvas.freeDrawingBrush.width = parseInt(size)
    // sprayBrush.density = size * densityFactor
  }
  function handleDensityChange(e) {
    const density = parseInt(e.target.value)
    
    setCurrentDensity(density)
    densityRef.current = density
    sprayBrush.density = density
  }

  function handleStencilChange(e) {
    setCurrentStencil(e.target.value)
    stencilRef.current = e.target.value
  }

  function showClearWarning() {
    setClearWarningActive(true)
  }
  function closeClearWarning() {
    setClearWarningActive(false)
  }

  function clearCanvas() {
    const objects = canvas.getObjects()

    objects.forEach(object => {
      canvas.remove(object)
    })
    setClearWarningActive(false)
  }

  function downloadImage() {
    setShareActive(true)

    const ext = "jpg";
    const base64 = canvas.toDataURL({
      format: ext,
      enableRetinaScaling: !window.Modernizr.touchevents // increases file size by about 10x
    });
    const link = document.createElement("a");
    link.href = base64;
    link.download = `wallart.${ext}`;
    link.click();
  }

  function closeSharing() {
    setShareActive(false)
  }

  function openModal() {
    setModalActive(true)
  }
  function closeModal() {
    setModalActive(false)
  }

  const fbShare = (e) => {
		var url = e.target.dataset.url;
		document.dispatchEvent(new CustomEvent('fbShare', {detail: {url: url} }))
	}
  
  const showForm = () => {
    setShareActive(false)
    setFormActive(true)
  }
  const closeForm = () => {
    setFormActive(false)
  }

  const chooseLayout = (e) => {
    setLayout(e.target.value)
    layoutRef.current = e.target.value
    setSize()
    setLayoutPicker(false)
  }

  const closeCustomImgForm = () => {
    setCustomImgForm(false)
  }

  const showCustomImgForm = () => {
    setCustomImgForm(true)
  }

  const customImageAdded = (image_url) => {
    console.log(image_url)
    setCustomImgForm(false)

    var img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = image_url;
    img.onload = function() {

      fabric.Image.fromURL(image_url, image => {
        canvas.add(image)
        canvas.centerObject(image)
        startMoveMode()
      })
    }
    
  }

  return(
    <div ref={containerRef} className={classes.paint} >
      <div ref={canvasWrapRef} className={classes.canvasWrap} data-layout={layout}>
        <canvas ref={canvasRef} id="canvas"></canvas>

        <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/tape-h_1.png' className={classes.tape} data-position='top' />
        <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/tape-h_1.png' className={classes.tape} data-position='bottom' />
        <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/tape-v_1.png' className={classes.tape} data-position='left' />
        <img src='https://d1ebxi7lthqj9n.cloudfront.net/images/tape-v_1.png' className={classes.tape} data-position='right' />
        
      </div>
      <Controls 
        mode={mode}
        currentColor={currentColor}
        currentSize={currentSize}
        currentDensity={currentDensity}
        currentStencil={currentStencil}
        showClearWarning={showClearWarning}
        handleColorChange={handleColorChange}
        handleSizeChange={handleSizeChange}
        handleDensityChange={handleDensityChange}
        handleStencilChange={handleStencilChange}
        startDrawMode={startDrawMode}
        startMarkerMode={startMarkerMode}
        startMoveMode={startMoveMode}
        startStencilMode={startStencilMode}
        addImage={addImage}
        undo={removeLastObject}
        downloadImage={downloadImage}
        showCustomImgForm={showCustomImgForm}
      />
      
      {!window.Modernizr.touchevents && (
        <div ref={cursorRef} className={classes.customCursor} data-mode={mode} data-stencil={currentStencil}></div>
      )}

      {layoutPicker && (
        <Modal
          allowClose={false}
          >
          <h3>Choose an image layout</h3>
          <RadioButtons
            shape='square'
            handleChange={chooseLayout}
            options={[
              {value: 'full', text: 'Full', icon: <IconFullScreen />},
              {value: 'square', text: 'Square', icon: <IconSquare />},
              {value: 'portrait',  text: 'Portrait', icon: <IconPortrait />}
            ]}
          />
        </Modal>
      )}

      {modalActive && (
        <Modal
          modalActive={modalActive}
          closeModal={closeModal}
          allowClose={true}
        >
        
          <p>
            Are you sure you want to leave? Your work will be lost.
          </p>
      
          <button className='btn' onClick={props.closeInteraction}>Back to Warehouse</button>

        </Modal>
      )}
      {clearWarningActive && (
        <Modal
          modalActive={clearWarningActive}
          closeModal={closeClearWarning}
          allowClose={true}
        >
        
          <p>
            Are you sure you want to clear the entire canvas?<br/>Your work will be lost.
          </p>
      
          <button className='btn' onClick={clearCanvas}>Clear Canvas</button>

        </Modal>
      )}
      {shareActive && (
        <Modal
          modalActive={shareActive}
          closeModal={closeSharing}
          allowClose={true}
          role='sharing'
        >
          <h3>Your Download Has Started</h3>
          <p>
            Share your image on socials or upload it to the warehouse:
          </p>

          <div className='btn-group'>
            <button onClick={fbShare} className='btn btn--block' data-url='https://meteora.linkinpark.com/'><IconFacebook /> SHARE</button>
            <a
              href="https://twitter.com/intent/tweet?text=Leave a message for Linkin Park by creating your own wall&url=https://meteora.linkinpark.com/&hashtags=Meteora20&via=linkinpark"
              className='btn btn--block'
            >
              <IconTwitter /> share
            </a>
            <button className='btn btn--block' onClick={showForm}>UPLOAD</button>
          </div>
        </Modal>
      )}

      {customImgForm && (
        <Modal
          modalActive={customImgForm}
          allowClose={true}
          closeModal={closeCustomImgForm}
        >
          <CustomImgForm closeForm={closeCustomImgForm} customImageAdded={customImageAdded} />
        </Modal>
      )}

      {formActive && (
        <Modal
          modalActive={formActive}
          allowClose={true}
          closeModal={closeForm}
        >
          <Form closeForm={closeForm} />
        </Modal>
      )}
    </div>
  )
}

export default Paint