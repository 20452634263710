import styles from './Loading.module.scss'

const loading = (props) => (
  <div className={styles.loading}>
    {/* <p className={styles.loadingCopy}>
      Join the community of vinyl lovers and find a limited edition of the 'Unlimited Love' record. Check out the pins on the map near you and share your photo.
    </p>
    <p className={styles.loadingCopy}>
      Once you've snagged your edition of choice, snap a photo and add it to the pin to share on the fan feed. 
    </p> */}

    {props.loadingComplete
      ?
      <button onClick={props.confirmLoading} className='btn btn--primary btn--block btn--lg'>LET'S GO</button>
      :
      <div className={styles.loadingBar}>
        <div className={styles.loadingText}>
          EXPERIENCE LOADING...
        </div>
      </div>
    }
  </div>
)

export default loading;