function IconShazam() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="shazam" d="M1.25,11.25a10,10,0,1,1,10,10A10,10,0,0,1,1.25,11.25ZM8.259,9.359a2.268,2.268,0,0,0,3.207,3.207L12.5,11.528a.7.7,0,0,1,.987.987l-1.038,1.038A3.663,3.663,0,0,1,7.272,8.372L9.539,6.106a.7.7,0,0,1,.987.987L8.259,9.359Zm5.982,4.6a2.268,2.268,0,0,0-3.207-3.207L10,11.79a.7.7,0,0,1-.987-.987l1.038-1.038a3.663,3.663,0,0,1,5.181,5.181l-2.266,2.267a.7.7,0,0,1-.987-.987l2.267-2.267Z" transform="translate(-1.25 -1.25)"/>
    </svg>
  )
}

export default IconShazam;

