import { useState } from "react";
import classes from './Screens.module.scss'

function EmailForm(props) {
  const [email, setEmail] = useState()
  const [error, setError] = useState()

  const handleSubmit = (e) => {
    e.preventDefault()

    if (email && email !== '') {
      props.emailAdded();
      
      // RECAPTCHA
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute("6LeyEyskAAAAAP3BUXKH7ynHCzWZWHdVwZEt_3eM", {action: 'submit'}).then(token => {
          fetch('/contacts', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({email, token})
          })
          .then(response => response.json())
          .then(data => {
            props.emailAdded()
            // if (data.error) {
            //   if (data.subcode && data.subcode === 100) {
            //     // Invalid recaptcha
            //   }
            // }
          })
          .catch(error => {
            setError(error)
          })
        })
      })
    } else {
      setError('Please enter your email address')
    }
  }

  return(
    <form onSubmit={handleSubmit} className='block--300'>
      <p>
        Add your email to enter:
      </p>

      <div className="input-row">
        <input
          type='email'
          name='email'
          onChange={e => setEmail(e.target.value)}
          placeholder='Email address...'
        />
      </div>

      <p>
        {error}
      </p>

      <div id='recaptcha-container'></div>
      <div className="input-row">
        <button type='submit' className='btn btn--block'>ADD EMAIL</button>
      </div>
    </form>
  )
}

export default EmailForm;