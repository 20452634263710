import { Fragment, useEffect, useRef, useState } from 'react';
import IconAngleLeft from '../Icons/IconAngleLeft'
import IconAngleRight from '../Icons/IconAngleRight'
import classes from './Gallery.module.scss'
import Gallery1 from './Gallery1';
// import Gallery2 from './Gallery2';
import Gallery3 from './Gallery3';
import Gallery4 from './Gallery4';
import Gallery5 from './Gallery5';

function Gallery(props) {
  const wrapperRef = useRef()
  const [totalItems, setTotalItems] = useState(0)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (props.currentItem.gallery === 1) {
      setTotalItems(79)
    } else if (props.currentItem.gallery === 2) {
      setTotalItems(20)
    } else if (props.currentItem.gallery === 3) {
      setTotalItems(65)
    } else if (props.currentItem.gallery === 4) {
      setTotalItems(15)
    } else if (props.currentItem.gallery === 5) {
      setTotalItems(46)
    }
  }, [])

  function navigate(e) {
    const direction = e.target.dataset.direction
    // const allItems = wrapperRef.current.children
    // const activeItem = wrapperRef.current.querySelector('[data-active="true"]')
    // const activeIndex = parseInt(activeItem.dataset.index)
    let targetIndex

    if (direction === 'next') {
      if (index === totalItems - 1) {
        targetIndex = 0
      } else {
        targetIndex = index + 1
      }
    } else if (direction === 'prev') {
      if (index === 0) {
        targetIndex = totalItems - 1
      } else {
        targetIndex = index - 1
      }
    }
    
    setIndex(targetIndex)
    // const targetItem = allItems[targetIndex]
    // activeItem.dataset.active = false
    // targetItem.dataset.active = true
  }

  return(
      <Fragment>

        <div ref={wrapperRef} className={classes.imageWrap}>
          {props.currentItem.gallery === 1 && (
            <Gallery1 index={index} />
          )}
          {/* {props.currentItem.gallery === 2 && (
            <Gallery2 index={index} />
          )} */}
          {props.currentItem.gallery === 3 && (
            <Gallery3 index={index} />
          )}
          {props.currentItem.gallery === 4 && (
            <Gallery4 index={index} />
          )}
          {props.currentItem.gallery === 5 && (
            <Gallery5 index={index} />
          )}
          <button className={`btn btn--outline btn--circle ${classes.control}`} onClick={navigate} data-direction='prev'><IconAngleLeft /></button>
          <button className={`btn btn--outline btn--circle ${classes.control}`} onClick={navigate} data-direction='next'><IconAngleRight /></button>
        </div>  
        
        <div className={classes.counter}>
          {index + 1}/{totalItems}
        </div>
      </Fragment>
  )
}

export default Gallery