import classes from './Screens.module.scss';
import Modal from '../Ui/Modal';

function EnterWarning(props) {
 
  return(
    <div className={classes.wrapper}>
      <div className={classes.wrapperOverlay}></div>

      <Modal >
        <img className={classes.welcomeLogo} src='https://d1ebxi7lthqj9n.cloudfront.net/images/logo-main.png' />
  
        <p className='content-block text--warning block--400'>
        We recommend exploring this space on desktop for the best experience.
        </p>

        <div className='content-block'>
          <button onClick={props.enterSite} className='btn'>ENTER SITE</button>
        </div>
      </Modal>

    </div>
  )
}
  
export default EnterWarning;